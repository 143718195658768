export const LatestSort = (documents) => {
    return Object.values({
        ...documents,
    }).sort((a, b) => {
        if (a.createdAt === b.createdAt)
            return (() => {
                if (a.__cos === b.__cos) return b.__cs - a.__cs;

                return b.__cos - a.__cos;
            })();

        return b.createdAt - a.createdAt;
    });
};
