export const cdnLink = (file, version) => {
    const origin = 'https://dksh.co.ke'.includes(window.location.origin)
        ? 'https://proxy.dksh.co.ke'
        : 'http://localhost:8001';

    if (typeof version === 'string')
        return `${origin}/cdn/${file}?v=${version}`;

    return `${origin}/cdn/${file}`;
};
