import { Loader } from './Loader';

export const CircularImage = ({
    width,
    src,
    className,
    background,
    hasLoader,
    loader,
}) => {
    return (
        <div
            className={`${width} ${className} rounded-full ${background} relative`}
        >
            <div className={`aspect-w-1 aspect-h-1 relative z-10`}>
                <img
                    className="iNOS-display-topmiddle w-full h-full rounded-full"
                    src={src}
                    alt=""
                />
            </div>
            {hasLoader && (
                <div className="iNOS-display-middle z-0">
                    <Loader {...loader} />
                </div>
            )}
        </div>
    );
};
