import { AccountBalancePreview } from './AccountBalancePreview';
import { ReferralLink } from './ReferralLink';
import { ManagersProvider } from '../ManagersProvider';
import { ProfileInfoSection } from './ProfileInfoSection';

const HomeAuthenticated = () => {
    return (
        <div className="w-full h-full items-center justify-center flex flex-col space-y-10 py-10">
            <div className="flex flex-col space-y-5 w-full">
                <ProfileInfoSection />
                <div className="w-full flex flex-row items-center justify-center">
                    <div className="flex flex-col sm:flex-row space-y-3 sm:space-y-0 space-x-5">
                        {[
                            {
                                path: (
                                    <>
                                        <path d="M480-46Q328-81 229-216.056T130-516v-266l350-132 350 132V-491.333Q830-479 828-467q-8-2-16.845-2.5-8.845-.5-18.155-.5-86 0-151.5 58T576-264.047V-82q-22.542 12-46.271 21T480-46Zm211.12-2Q676-48 666-59.5 656-71 656-86.055V-228q0-16 9.5-26t25.5-10h5v-40q0-36 25.788-61 25.787-25 62-25Q820-390 846-365t26 61v40h5q16 0 25.5 10t9.5 26v141.945Q912-71 902-59.5T876.88-48H691.12ZM744-264h80v-40q0-17-11.5-28.5T784-344q-17 0-28.5 11.5T744-304v40Z" />
                                    </>
                                ),
                                type: 'savings',
                                viewBox: '0 -960 960 960',
                            },
                            {
                                path: (
                                    <>
                                        <path d="M708.461-65.233 647.308-125l60.001-61H461.615V-272h245.694l-60.001-61.001 61.153-59.768 163.768 163.768L708.461-65.232ZM65.003-440.462v-119.844h119.845v119.844H65.003Zm305.494 0q-57.189 0-97.573-40.414-40.384-40.414-40.384-97.585v-160q0-57.171 40.427-97.585 40.426-40.414 97.615-40.414 57.189 0 97.572 40.414 40.384 40.414 40.384 97.585v160q0 57.171-40.426 97.585-40.427 40.414-97.615 40.414Zm363.69 0q-57.188 0-97.572-40.414t-40.384-97.585v-160q0-57.171 40.427-97.585 40.426-40.414 97.615-40.414 57.188 0 97.572 40.414t40.384 97.585v160q0 57.171-40.427 97.585-40.426 40.414-97.615 40.414ZM370.539-526.46q21.667 0 36.834-15.167 15.167-15.167 15.167-36.834v-160q0-21.667-15.167-36.833-15.167-15.167-36.834-15.167t-36.833 15.167q-15.167 15.166-15.167 36.833v160q0 21.667 15.167 36.834 15.166 15.167 36.833 15.167Zm363.691 0q21.667 0 36.834-15.167 15.166-15.167 15.166-36.834v-160q0-21.667-15.166-36.833-15.167-15.167-36.834-15.167t-36.834 15.167q-15.166 15.166-15.166 36.833v160q0 21.667 15.166 36.834 15.167 15.167 36.834 15.167Z" />
                                    </>
                                ),
                                type: 'wallet',
                                viewBox: '0 -960 960 960',
                            },
                        ].map((account, i) => (
                            <AccountBalancePreview key={i} {...{ account }} />
                        ))}
                    </div>
                </div>
            </div>
            <ManagersProvider />
            <ReferralLink />
        </div>
    );
};

export default HomeAuthenticated;
