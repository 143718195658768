import { cdnLink } from '../utils';

export const ContentLoader = ({ width }) => {
    return (
        <div className={`${width}`}>
            <div className="aspect-w-1 aspect-h-1 relative">
                <img
                    src={cdnLink('./loader.gif')}
                    className="iNOS-display-middle w-full h-full object-cover"
                    alt=""
                />
            </div>
        </div>
    );
};
