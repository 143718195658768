import { useEffect, useState } from 'react';
import {
    ActiveButton,
    Center,
    Form,
    Input,
    Link,
    Logo,
} from '../../components';
import { keysToEntries } from '../../utils';
import { useAuth, useBackend, useLinkClick } from '../../hooks';

const SigninComponent = () => {
    const [input, setInput] = useState({});
    const { navigateTo } = useLinkClick();
    const {
        isMember,
        isAnonymous,
        isTwoFactorAuthentication,
        isPasswordReset,
    } = useAuth();
    const { post } = useBackend();

    useEffect(() => {
        if (isMember) return navigateTo('/');
        if ([isAnonymous, isTwoFactorAuthentication].includes(true))
            return navigateTo('/verify');
        if (isPasswordReset) return navigateTo('/reset');
    }, [isMember, isAnonymous, isTwoFactorAuthentication, isPasswordReset]);

    if (
        [
            isAnonymous,
            isMember,
            isTwoFactorAuthentication,
            isPasswordReset,
        ].includes(true)
    )
        return;

    return (
        <div className="h-full w-full flex flex-col items-center justify-center py-5 md:py-10">
            <div className="md:max-w-[600px] w-full md:border p-5 md:p-7 bg-white rounded-2xl space-y-5">
                <Form
                    className="space-y-5"
                    onSubmit={() => {
                        post({
                            exec: 'Signin',
                            client: {
                                account: {
                                    ...input,
                                },
                            },
                        });
                    }}
                >
                    <div className="w-full flex items-center justify-center">
                        <Logo />
                    </div>
                    {Object.entries({
                        ...keysToEntries('username', 'password'),
                    }).map(([key], i) => (
                        <Input
                            required={true}
                            label={key}
                            type={key}
                            name={key}
                            key={i}
                            autoComplete={
                                ['password'].includes(key)
                                    ? 'current-password'
                                    : key
                            }
                            onChange={(e) => {
                                setInput({
                                    ...input,
                                    [e.target.name]: e.target.value,
                                });
                            }}
                        />
                    ))}
                    <div className="flex flex-row space-x-2 text-sm justify-between">
                        <span className="text-blue-500 font-bold active:underline">
                            <Link to={'/reset'}>Forgot password?</Link>
                        </span>
                    </div>
                    <div className="flex flex-row justify-center items-center w-full">
                        <ActiveButton
                            type="submit"
                            className="p-5 bg-black w-full rounded-full hero-pattern-glamorous-60 text-red-700 font-black"
                            loader={{
                                width: 'w-[28px]',
                                border: 'border-t-white border-l-red-500 border-b-green-500',
                            }}
                        >
                            <Center>Sign in</Center>
                        </ActiveButton>
                    </div>
                </Form>
                <div className="flex flex-row space-x-2 text-sm justify-between md:hidden">
                    <span className="text-blue-500 font-bold active:underline">
                        <Link to={'/signup'}> Don't have an account?</Link>
                    </span>
                </div>
                <div className="md:flex hidden flex-row space-x-2">
                    <div className="font-bold text-gray-800">
                        Don't have an account?
                    </div>
                    <ActiveButton
                        loader={{
                            width: 'w-[24px]',
                            border: 'border-t-gray-500 border-l-red-500 border-b-green-500',
                        }}
                        onClick={() => {
                            navigateTo('/signup');
                        }}
                    >
                        <div className="text-blue-500 font-bold">Sign up.</div>
                    </ActiveButton>
                </div>
            </div>
        </div>
    );
};

export const SigninPage = {
    path: '/signin',
    element: <SigninComponent />,
};
