export const Loader = ({ width, border, className }) => {
    return (
        <div className={`${width || 'w-[24px]'} ${className} rounded-full`}>
            <div className="aspect-w-1 aspect-h-1">
                <div
                    className={`w-full h-full border-4 animate-spin border-r-transparent rounded-full ${
                        border ? border : 'border-gray-500'
                    }`}
                ></div>
            </div>
        </div>
    );
};
