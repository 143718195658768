import { useRef, useState } from 'react';
import { capitalizeCharacterAt } from '../utils';
import { Svg } from './Svg';
import { useAuth } from '../hooks';

export const Input = ({
    label,
    type,
    placeholder,
    onChange,
    hide,
    space,
    arpClassName,
    labelClassName,
    labelDisplay,
    displayFileNameAsLabel,
    ...props
}) => {
    const [labelName, setLabelName] = useState(label);
    const inputRef = useRef();
    const [showPassword, setShowPassword] = useState(false);
    const { arp_name } = useAuth();

    console.log(arp_name);

    const togglePasswordVisibility = () => {
        setShowPassword((prevState) => !prevState);
    };

    return (
        <div
            className={`flex flex-col ${space?.y || 'space-y-5'}  ${
                hide && 'hidden'
            }`}
        >
            <label htmlFor={labelName}>
                <span
                    className={`text-base font-bold break-all line-clamp-1 ${
                        labelClassName || 'text-gray-800'
                    }`}
                >
                    {capitalizeCharacterAt(0, labelName)}
                </span>
            </label>
            {['description'].includes(`${type}`.toLocaleLowerCase()) ? (
                <div
                    contentEditable
                    suppressContentEditableWarning
                    className="p-5 h-[180px] max-h-[180px] rounded-3xl overflow-scroll hide-scroll border outline-none font-semibold"
                    onInput={(e) => {
                        e.target.name = label;
                        e.target.value = e.currentTarget.textContent;
                        onChange(e);
                    }}
                    {...props}
                >
                    {placeholder}
                </div>
            ) : (
                <div className="flex items-center space-x-5 relative">
                    <input
                        ref={inputRef}
                        className={
                            ['arp'].includes(type)
                                ? `block w-full py-2 text-md appearance-none italic file:not-italic font-semibold line-clamp-1 file:mr-4 file:rounded-full file:hero-pattern-glamorous-60 file:border-0 outline-none file:py-3 file:px-5 file:text-sm file:font-bold file:transition-all file:duration-700 file:ease-in-out ${
                                      arpClassName ||
                                      'file:text-red-700 file:bg-black active:file:text-red-900 text-gray-700'
                                  }`
                                : `py-5 px-5 ${
                                      ['password', 'confirm password'].includes(
                                          `${type}`.toLocaleLowerCase()
                                      ) && 'pr-14'
                                  } w-full border rounded-full text-sm outline-none font-semibold placeholder:font-semibold placeholder:text-black bg-white`
                        }
                        {...{
                            onChange: (e) => {
                                if (
                                    ['arp'].includes(type) &&
                                    displayFileNameAsLabel === true
                                ) {
                                    const file = e.target.files[0];

                                    setLabelName(file?.name);
                                }

                                onChange(e);
                            },
                            placeholder,
                        }}
                        {...{
                            ...props,
                            type,
                            ...(['password', 'confirm password'].includes(
                                `${type}`.toLocaleLowerCase()
                            ) && { type: showPassword ? 'text' : 'password' }),
                            ...(['o.t.p', 'name'].includes(
                                `${type}`.toLocaleLowerCase()
                            ) && { type: 'text' }),
                            ...(['arp'].includes(
                                `${type}`.toLocaleLowerCase()
                            ) && {
                                type: 'file',
                            }),
                        }}
                        id={label}
                    />
                    {['password', 'confirm password'].includes(
                        `${type}`.toLocaleLowerCase()
                    ) && (
                        <div className="iNOS-display-right px-5">
                            <Svg
                                className={'fill-red-500'}
                                onClick={() => {
                                    togglePasswordVisibility();
                                }}
                            >
                                {showPassword ? (
                                    <>
                                        <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7 7 0 0 0 2.79-.588M5.21 3.088A7 7 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474z" />
                                        <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12z" />
                                    </>
                                ) : (
                                    <>
                                        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                                        <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                                    </>
                                )}
                            </Svg>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
