import { createContext, useEffect, useState } from 'react';
import { Loader } from '../components';
import { useBackend } from '../hooks';

export const ScreenLoaderContext = createContext();

export const ScreenLoaderProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const { listener } = useBackend();

    const ScreenLoader = () => {
        return (
            loading && (
                <div className="iNOS-display-topmiddle py-10 z-[5000]">
                    <div className="p-3 rounded-full bg-white border-4 border-t-gray-500 border-l-red-500 border-b-green-500 shadow-lg hero-pattern-glamorous-80">
                        <Loader
                            width="w-[28px]"
                            border="border-t-gray-500 border-l-red-500 border-b-green-500"
                        />
                    </div>
                </div>
            )
        );
    };

    useEffect(() => {
        return listener('loading', ({ loading }) => {
            setLoading(loading);
        });
    }, []);

    return (
        <ScreenLoaderContext.Provider
            value={{
                ScreenLoader,
                setLoading,
                loading,
            }}
        >
            {children}
        </ScreenLoaderContext.Provider>
    );
};
