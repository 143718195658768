import { useEffect, useState } from 'react';
import { keysToEntries } from '../../utils';
import { Form } from '../Form';
import { Input } from '../Input';
import { Logo } from '../Logo';
import { ActiveButton } from '../ActiveButton';
import { Center } from '../Center';
import { useBackend, usePayment, useScroll } from '../../hooks';
import { Link } from '../Link';

export const Send_Money = () => {
    const [input, setInput] = useState({});

    const { triggerScrollTop } = useScroll();
    const { token, fullname, username, amount, setPayment } = usePayment();
    const { post } = useBackend();

    useEffect(() => {
        setPayment({});
    }, []);

    useEffect(() => {
        triggerScrollTop();
    }, [token]);

    return (
        <div className="h-full w-full flex flex-col items-center justify-center py-5 md:py-10">
            <div className="md:max-w-[600px] w-full md:border p-5 md:p-7 bg-white rounded-2xl space-y-5">
                <Form
                    className="space-y-5"
                    onSubmit={() => {
                        post({
                            exec: token
                                ? 'PersonalAccountConfirmPayment'
                                : 'PersonalAccountSendMoney',
                            client: {
                                payment: { ...input, token },
                            },
                        });

                        token && setPayment({});
                    }}
                >
                    <div className="w-full flex items-center justify-center">
                        <Logo />
                    </div>
                    {Object.entries({
                        ...keysToEntries('username', 'amount'),
                        ...(token && {
                            username,
                            fullname,
                            amount,
                            ...keysToEntries('password'),
                        }),
                    }).map(([key, value], i) => (
                        <Input
                            required={true}
                            label={key}
                            type={key}
                            name={key}
                            key={i}
                            autoComplete="off"
                            onChange={(e) => {
                                setInput({
                                    ...input,
                                    [e.target.name]: e.target.value,
                                });
                            }}
                            {...(['password'].includes(key) !== true &&
                                typeof token !== 'undefined' && {
                                    placeholder: value,
                                    disabled: true,
                                })}
                        />
                    ))}
                    {token && (
                        <div className="flex flex-row space-x-2 text-sm justify-between">
                            <span className="text-blue-500 font-bold active:underline">
                                <Link
                                    onClick={() => {
                                        setPayment({});
                                    }}
                                >
                                    Cancel payment?
                                </Link>
                            </span>
                        </div>
                    )}
                    <div className="flex flex-row justify-center items-center w-full">
                        <ActiveButton
                            type="submit"
                            className="p-5 bg-black w-full rounded-full hero-pattern-glamorous-60 text-red-700 font-black"
                            loader={{
                                width: 'w-[28px]',
                                border: 'border-t-gray-200 border-l-red-500 border-b-green-500',
                            }}
                        >
                            <Center>
                                {token ? 'Confirm Payment' : 'Send Money'}
                            </Center>
                        </ActiveButton>
                    </div>
                </Form>
            </div>
        </div>
    );
};
