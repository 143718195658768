export const RenderHub = (components, render, props) => {
    render = `${render}`.toLocaleLowerCase();

    return (Notfound) => {
        if (
            Object.keys({ ...components })
                .map((key) => key.toLocaleLowerCase())
                .includes(render) !== true
        )
            return <Notfound />;

        return Object.entries({ ...components }).map(([key, Value], i) => {
            return (
                [render].includes(key.toLocaleLowerCase()) && (
                    <Value key={i} {...(props ? props : {})} />
                )
            );
        });
    };
};
