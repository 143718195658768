import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { parseSearchParams } from '../utils';

export const useSearchParams = () => {
    const location = useLocation();
    const [searchParams, setSearchParams] = useState(
        parseSearchParams(location.search)
    );

    useEffect(() => {
        setSearchParams(parseSearchParams(location.search));
    }, [location.search]);

    return searchParams;
};
