import { createContext } from 'react';

export const BackendContext = createContext();

export const BackendProvider = ({ children }) => {
    return (
        <BackendContext.Provider
            value={{ ...(window.dksh && { ...window?.dksh() }) }}
        >
            {children}
        </BackendContext.Provider>
    );
};
