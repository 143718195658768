export const sendArpToServer = ({ e, setInput, input }) => {
    return (cb) => {
        if (['arp'].includes(e.target.name) !== true) return cb && cb();

        const file = e.target.files[0];

        if (file instanceof Blob !== true) return;

        const reader = new FileReader();

        reader.onload = () => {
            const fileData = reader.result;

            setInput({
                ...input,
                [e.target.name]: {
                    name: file.name,
                    file: btoa(
                        String.fromCharCode.apply(
                            null,
                            new Uint8Array(fileData)
                        )
                    ),
                },
            });
        };

        reader.readAsArrayBuffer(file);
    };
};
