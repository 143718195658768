import { useEffect, useRef, useState } from 'react';
import { useBackend, useDatabase } from '../../../../../hooks';
import { debounce, elementListener, scroll } from '../../../../../utils';
import { ActiveButton } from '../../../../ActiveButton';
import { Svg } from '../../../../Svg';
import { Loader } from '../../../../Loader';

export const CachesHeader = ({ setPointer, pointer }) => {
    const { post } = useBackend();
    const {
        databaseCaches: { count, caches },
    } = useDatabase();
    const scrollEditorContainerRef = useRef();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(caches?.length !== count);
    }, [count, caches]);

    useEffect(() => {
        post(
            {
                exec: 'DatabaseCaches',
                client: {
                    items: 0,
                },
            },
            { auht: true, loader: false }
        );
    }, []);

    useEffect(() => {
        return elementListener(scrollEditorContainerRef)(
            'scroll',
            debounce(
                scroll(({ xScrollPercentage }) => {
                    xScrollPercentage > 40 &&
                        post(
                            {
                                exec: 'DatabaseCaches',
                                client: {
                                    items: caches.length,
                                },
                            },
                            {
                                auht: true,
                                loader: false,
                                lock: caches.length === count,
                            }
                        );
                }),
                300
            )
        );
    });

    return (
        caches && (
            <div
                ref={scrollEditorContainerRef}
                className="flex flex-row bg-yellow-500 hero-pattern-glamorous-60 w-full overflow-x-auto scrollbar-thin"
            >
                {[...caches].map((cache, i) => (
                    <ActiveButton
                        key={i}
                        loader={{
                            width: 'w-[18px]',
                            border: 'border-t-gray-500 border-l-red-500 border-b-green-500',
                        }}
                        onClick={() => {
                            setPointer({
                                active: 'cache',
                                name: cache,
                            });
                        }}
                    >
                        <div
                            key={i}
                            className={`p-5 ${
                                [pointer.name].includes(cache) && 'shadow-md'
                            } py-3 space-x-4 flex flex-row items-center active:bg-red-900 active:text-red-600 active:hero-pattern-hideout`}
                        >
                            <div>
                                <Svg
                                    fill={'fill-green-700 active:fill-red-600'}
                                    width={'w-[20px]'}
                                >
                                    {[pointer.name].includes(cache) ? (
                                        <path d="M8 16a6 6 0 0 0 6-6c0-1.655-1.122-2.904-2.432-4.362C10.254 4.176 8.75 2.503 8 0c0 0-6 5.686-6 10a6 6 0 0 0 6 6M6.646 4.646l.708.708c-.29.29-1.128 1.311-1.907 2.87l-.894-.448c.82-1.641 1.717-2.753 2.093-3.13" />
                                    ) : (
                                        <>
                                            <path d="M7.21.8C7.69.295 8 0 8 0q.164.544.371 1.038c.812 1.946 2.073 3.35 3.197 4.6C12.878 7.096 14 8.345 14 10a6 6 0 0 1-12 0C2 6.668 5.58 2.517 7.21.8m.413 1.021A31 31 0 0 0 5.794 3.99c-.726.95-1.436 2.008-1.96 3.07C3.304 8.133 3 9.138 3 10c0 0 2.5 1.5 5 .5s5-.5 5-.5c0-1.201-.796-2.157-2.181-3.7l-.03-.032C9.75 5.11 8.5 3.72 7.623 1.82z" />
                                            <path d="M4.553 7.776c.82-1.641 1.717-2.753 2.093-3.13l.708.708c-.29.29-1.128 1.311-1.907 2.87z" />
                                        </>
                                    )}
                                </Svg>
                            </div>
                            <div className="font-semibold max-w-[500px] text-green-700 text-sm flex flex-row">
                                <p className="truncate">{cache}</p>
                            </div>
                        </div>
                    </ActiveButton>
                ))}
                {loading && (
                    <div className="px-5 py-3 space-x-4 flex flex-row items-center">
                        <Loader
                            width={'w-[20px]'}
                            border={
                                'border-t-gray-500 border-l-white border-b-red-500'
                            }
                        />
                    </div>
                )}
            </div>
        )
    );
};
