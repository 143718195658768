import { addCommasToNumber } from '../../utils';
import { useNumberAnimator, useWallets } from '../../hooks';
import { Svg } from '../Svg';

export const AccountBalancePreview = ({ account }) => {
    const { personalAccountBalance } = useWallets();
    const { currentValue } = useNumberAnimator({
        endValue: { ...personalAccountBalance }[account?.type],
        duration: 1500,
    });

    return (
        <div className="flex flex-row items-center justify-center space-x-5">
            <Svg
                viewBox={account?.viewBox}
                fill={'fill-red-500'}
                width={'w-[28px]'}
                className="p-3 border-4 border-t-gray-500 border-l-red-500 border-b-green-500 hero-pattern-glamorous-80 bg-white shadow-lg rounded-full"
            >
                {account?.path}
            </Svg>
            <div className="font-bold text-xl border text-red-500 px-3 py-2 hero-pattern-glamorous bg-white rounded-full">
                <div className="relative">
                    <div className="opacity-0">
                        {addCommasToNumber(10000000)}
                    </div>
                    <div className="iNOS-display-middle w-full text-center overflow-y-auto hide-scroll rounded-full">
                        {addCommasToNumber(currentValue)}
                    </div>
                </div>
            </div>
        </div>
    );
};
