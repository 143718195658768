import { useAuth, useBackend, useToast } from '../../hooks';
import { cdnLink } from '../../utils';
import { CircularImage } from '../CircularImage';
import { Svg } from '../Svg';
import { UpcomingUpdate } from '../UpcomingUpdate';

export const ProfileInfoSection = () => {
    const { setCopy, setMessage } = useToast();
    const { username, fullname } = useAuth();
    const { post } = useBackend();

    return (
        <>
            <div className="w-full max-w-screen-md m-auto flex items-center justify-center relative">
                <div className={`relative`}>
                    <div className="border-4 rounded-full bg-black border-t-gray-500 border-l-red-500 border-b-green-500">
                        <CircularImage
                            padding={'p-5'}
                            width={'w-[150px]'}
                            className={`rounded-full overflow-hidden shadow-lg border-4 border-black`}
                            src={cdnLink('./dksh.jpg')}
                            hasLoader={true}
                            loader={{
                                width: 'w-[34px]',
                                border: 'border-t-white border-l-red-500 border-b-green-500',
                            }}
                            background={'bg-black hero-pattern-glamorous-80'}
                        />
                    </div>
                    <div className="iNOS-display-topleft -mt-5 -ml-5 p-5 z-[5000]">
                        <Svg
                            width={'w-[28px]'}
                            fill={'fill-red-500'}
                            className={
                                'p-2 border-4 border-t-gray-500 border-l-red-500 border-b-green-500 hero-pattern-glamorous-70 bg-white rounded-full'
                            }
                            viewBox="0 -960 960 960"
                            hasLoader={true}
                            loader={{
                                width: 'w-[24px]',
                                border: 'border-t-gray-500 border-l-red-500 border-b-green-500',
                            }}
                            onClick={() => {
                                setMessage(
                                    <UpcomingUpdate label={'Profile'} />
                                );
                            }}
                        >
                            <path d="m204-495-16-76q-8-5-15-9t-15-9l-76 22-52-92 58-50v-36l-58-50 52-92 76 22q8-5 15-9t15-9l16-76h108l16 76q8 5 15 9t15 9l76-22 52 92-58 50v36l58 50-52 92-76-22q-8 5-15 9t-15 9l-16 76H204Zm54-154q32 0 55-23t23-55q0-32-23-55t-55-23q-32 0-55 23t-23 55q0 32 23 55t55 23ZM566-2l-20-106q-12-4-23-10t-21-14L402-99l-74-125 82-72v-48l-82-72 74-125 100 33q10-8 21-14t23-10l20-106h148l20 106q12 4 23 10t21 14l100-33 74 125-82 72v48l82 72-74 125-100-33q-10 8-21 14t-23 10L714-2H566Zm74-196q51 0 86.5-35.5T762-320q0-51-35.5-86.5T640-442q-51 0-86.5 35.5T518-320q0 51 35.5 86.5T640-198Z" />
                        </Svg>
                    </div>
                </div>
                <div className="iNOS-display-topright mx-3 z-[4000]">
                    <Svg
                        onClick={() => {
                            post({ exec: 'Signout' }, { auth: true });
                        }}
                        loader={{
                            width: 'w-[24px]',
                            border: 'border-t-gray-500 border-l-red-500 border-b-green-500',
                        }}
                        hasLoader={true}
                        fill={'fill-red-500'}
                        width={'w-[28px]'}
                        className="p-3 border-4 border-t-gray-500 border-l-red-500 border-b-green-500 bg-white hero-pattern-glamorous-80 shadow-lg rounded-full"
                    >
                        <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0m-9 8c0 1 1 1 1 1h5v-1a2 2 0 0 1 .01-.2 4.49 4.49 0 0 1 1.534-3.693Q8.844 9.002 8 9c-5 0-6 3-6 4m7 0a1 1 0 0 1 1-1v-1a2 2 0 1 1 4 0v1a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1zm3-3a1 1 0 0 0-1 1v1h2v-1a1 1 0 0 0-1-1" />
                    </Svg>
                </div>
            </div>
            <div className="w-full max-w-screen-md m-auto flex items-center justify-center relative">
                <div className="flex flex-col break-all space-y-5 py-5 max-w-sm w-full">
                    <div className="text-blue-500 text-xl text-center font-semibold italic">
                        <p className="line-clamp-1 px-5">@{username}</p>
                    </div>
                    <div className="text-2xl font-semibold font-serif text-center">
                        <p className="line-clamp-1 px-5">{fullname}</p>
                    </div>
                </div>
                <div className="iNOS-display-topright mx-3 z-[4000]">
                    <Svg
                        onClick={() => {
                            setCopy(
                                <div className="w-full flex flex-col space-y-2">
                                    <div className="flex flex-row space-x-1">
                                        Username: {username}
                                    </div>
                                    <div className="flex flex-row">
                                        Fullname: {fullname}
                                    </div>
                                </div>
                            );
                        }}
                        loader={{
                            width: 'w-[24px]',
                            border: 'border-t-gray-500 border-l-red-500 border-b-green-500',
                        }}
                        hasLoader={true}
                        fill={'fill-red-500'}
                        width={'w-[28px]'}
                        className="p-3 border-4 border-t-gray-500 border-l-red-500 border-b-green-500 hero-pattern-glamorous-80 bg-white shadow-lg rounded-full"
                    >
                        <path d="M6.5 0A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0zm3 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5z" />
                        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1A2.5 2.5 0 0 1 9.5 5h-3A2.5 2.5 0 0 1 4 2.5zM10 8a1 1 0 1 1 2 0v5a1 1 0 1 1-2 0zm-6 4a1 1 0 1 1 2 0v1a1 1 0 1 1-2 0zm4-3a1 1 0 0 1 1 1v3a1 1 0 1 1-2 0v-3a1 1 0 0 1 1-1" />
                    </Svg>
                </div>
            </div>
        </>
    );
};
