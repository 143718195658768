export const DKSH = ({ D, K, S, H, all }) => {
    return (
        <>
            {[
                { letter: 'D', className: `${D || all || 'text-gray-500 '}` },
                {
                    letter: 'K',
                    className: `line-through ${K || all || 'text-red-500'}`,
                },
                {
                    letter: 'S',
                    className: `line-through ${S || all || 'text-green-500'}`,
                },
                {
                    letter: 'H',
                    className: `line-through ${H || all || 'text-gray-300'}`,
                },
            ].map((graphic, i) => (
                <span key={i} className={graphic?.className}>
                    {graphic?.letter}
                </span>
            ))}
        </>
    );
};
