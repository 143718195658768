import { useEffect } from 'react';
import { StellarDatabaseHeader } from './StellarDatabaseHeader';
import { useBackend } from '../../../../../hooks';
import { Svg } from '../../../../Svg';

export const StellarDatabase = () => {
    const { post } = useBackend();

    useEffect(() => {
        post({ exec: 'DatabaseStats' }, { auth: true, loader: false });
    }, []);

    return (
        <div className="w-full max-w-screen-md m-auto">
            <div className="space-y-3">
                <div className="w-full relative space-y-5 md:space-y-0 md:border md:px-5 md:rounded-3xl md:hero-pattern-glamorous-80 md:shadow-lg md:bg-white overflow-hidden">
                    <div className="w-full flex items-center justify-center">
                        <div className="flex items-center justify-center space-x-5 md:py-3">
                            <div>
                                <Svg fill={'fill-red-500'} width={'w-[28px]'}>
                                    <path d="M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09zM4.157 8.5H7a.5.5 0 0 1 .478.647L6.11 13.59l5.732-6.09H9a.5.5 0 0 1-.478-.647L9.89 2.41z" />
                                </Svg>
                            </div>
                            <div className="font-bold text-red-500 text-lg">
                                Stellar Database
                            </div>
                        </div>
                    </div>
                    <div className="w-full overflow-hidden relative border-4 border-t-gray-500 border-l-red-500 border-b-green-500 shadow-lg rounded-3xl bg-white">
                        <StellarDatabaseHeader />
                    </div>
                </div>
            </div>
        </div>
    );
};
