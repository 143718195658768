import anime from 'animejs/lib/anime.es.js';

export const useAnime = () => {
    return {
        anime: (options) => {
            const animation = anime({ ...options });

            return () => {
                animation.reverse();
                animation.pause();
                animation.remove();
            };
        },
    };
};
