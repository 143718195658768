import { useLinkClick, useToast, useWallets, useGrab } from '../hooks';
import { spaceToUnderscore } from '../utils';
import { ActiveButton } from './ActiveButton';
import { Badge } from './Badge';
import { Svg } from './Svg';
import { UpcomingUpdate } from './UpcomingUpdate';

export const ManagerPreviewCard = ({ manager }) => {
    const { navigateTo } = useLinkClick();

    const { clear } = useToast();
    const { setMessage } = useToast();
    const { active_managers } = useGrab();

    const { personalAccountStatementNotificationsCount } = useWallets();

    return (
        <div className="w-full bg-white hero-pattern-glamorous-80 border rounded-3xl shadow-lg p-3 relative">
            <Badge
                count={
                    {
                        [`Statement`]:
                            personalAccountStatementNotificationsCount,
                    }[manager?.label]
                }
            >
                <div className="aspect-w-2 aspect-h-1 bg-white rounded-3xl border-4 border-t-gray-500 border-l-red-500 border-b-green-500"></div>
                <div
                    className="iNOS-display-middle w-full h-full flex items-center justify-center group z-50"
                    onClick={() => {
                        clear();

                        if (
                            [...active_managers].includes(manager?.label) !==
                            true
                        )
                            return setMessage(
                                <UpcomingUpdate label={manager?.label} />
                            );

                        navigateTo(
                            `/manager?q=${spaceToUnderscore(manager?.label)}`
                        );
                    }}
                >
                    <ActiveButton
                        loader={{
                            width: 'w-[28px]',
                            border: 'border-t-gray-500 border-l-red-500 border-b-green-500',
                        }}
                    >
                        <div className="flex flex-col w-full space-y-2 py-2 items-center justify-center">
                            <Svg
                                width={'w-[28px]'}
                                fill={'fill-red-500'}
                                viewBox={manager?.viewBox}
                                className={`${manager?.rotate}`}
                            >
                                {manager?.path}
                            </Svg>
                            <div className="font-bold text-red-500">
                                {manager?.label}
                            </div>
                        </div>
                    </ActiveButton>
                </div>
            </Badge>
        </div>
    );
};
