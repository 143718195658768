import { DKSH, CircularImage, Svg } from '../../..';
import {
    addCommasToNumber,
    cdnLink,
    parseTimestamp,
    truncateToTwoDecimalPlaces,
} from '../../../../utils';

export const StatementCard = ({
    statement,
    isSender,
    isRecipient,
    isSenderDKSHSavingsAccount,
    hasReferral,
}) => {
    const timestamp = parseTimestamp(statement?.timestamp, 'Africa/Nairobi');

    return (
        <div className="max-w-2xl mx-auto bg-white p-5 space-y-5 rounded border shadow-md">
            <div className="flex items-center space-x-3">
                <CircularImage
                    width={'w-[60px]'}
                    src={cdnLink('./harambee.gif')}
                    className={
                        'border-4 border-t-gray-500 rounded-full border-l-red-500 border-b-green-500'
                    }
                    hasLoader={true}
                    loader={{
                        width: 'w-[24px]',
                        border: 'border-t-gray-500 border-l-red-500 border-b-green-500',
                    }}
                    background={'bg-white hero-pattern-glamorous-80'}
                />
                <div>
                    <p className="text-lg font-bold text-red-500 line-clamp-1">
                        {isSender ? 'You' : statement?.sender?.fullname}
                    </p>
                    <p className="font-semibold text-blue-500 line-clamp-1">
                        {timestamp}
                    </p>
                </div>
            </div>
            <div>
                <div className="overflow-auto max-h-[200px] text-black hero-pattern-glamorous-80 font-medium break-all p-3 hide-scroll border-4 shadow-md bg-white rounded-3xl border-t-gray-500 border-l-red-500 border-b-green-500">
                    {isSender && (
                        <div>
                            <span className="text-red-500 italic">
                                {statement?.utid}
                            </span>{' '}
                            confirmed on{' '}
                            <span className="text-blue-500">{timestamp}</span>{' '}
                            you transfered{' '}
                            {addCommasToNumber(
                                truncateToTwoDecimalPlaces(statement?.amount)
                            )}{' '}
                            <DKSH /> to{' '}
                            <span className="text-blue-500">
                                {statement?.recipient?.fullname} @
                                {statement?.recipient?.username}
                            </span>
                            .{' '}
                            <span>
                                Transaction cost{' '}
                                {addCommasToNumber(
                                    truncateToTwoDecimalPlaces(statement?.fee)
                                )}{' '}
                                <DKSH />
                            </span>
                            . Thank you for using our services. Your new balance
                            is{' '}
                            {addCommasToNumber(
                                truncateToTwoDecimalPlaces(statement?.balance)
                            )}{' '}
                            <DKSH />.
                        </div>
                    )}
                    {isRecipient && (
                        <div>
                            <span className="text-red-500 italic">
                                {statement?.utid}
                            </span>{' '}
                            confirmed on{' '}
                            <span className="text-blue-500">{timestamp}</span>{' '}
                            you received{' '}
                            {addCommasToNumber(
                                truncateToTwoDecimalPlaces(statement?.amount)
                            )}{' '}
                            <DKSH /> from {isSenderDKSHSavingsAccount && 'your'}
                            {hasReferral && 'our'}{' '}
                            <span className="text-blue-500">
                                {statement?.sender?.fullname} @
                                {statement?.sender?.username}
                            </span>
                            {hasReferral && (
                                <span>
                                    {' '}
                                    for referring{' '}
                                    <span>
                                        <span className="text-blue-500">
                                            {statement?.referred?.fullname} @
                                            {statement?.referred?.username}
                                        </span>
                                    </span>
                                </span>
                            )}
                            . Thank you for using our services. Your new balance
                            is{' '}
                            {addCommasToNumber(
                                truncateToTwoDecimalPlaces(statement?.balance)
                            )}{' '}
                            <DKSH />.
                        </div>
                    )}
                </div>
            </div>
            <div className="flex items-center flex-row space-x-3">
                <Svg width={'w-[18px]'} fill={'fill-red-500'}>
                    <path d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5m14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5" />
                </Svg>
                <div className="text-lg font-bold text-red-500">
                    Transaction
                </div>
            </div>
        </div>
    );
};
