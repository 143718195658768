import { Svg } from '..';
import { useAuth, useToast } from '../../hooks';
import { spaceToUnderscore } from '../../utils';

export const ReferralLink = () => {
    const { setCopy } = useToast();
    const { username, fullname, uid } = useAuth();

    return (
        <div className="w-full max-w-screen-md m-auto px-3">
            <div className="flex flex-row items-center space-x-5">
                <Svg
                    onClick={() => {
                        setCopy(
                            <div className="w-full flex flex-col space-y-3">
                                <div>
                                    🤝 You have been invited by{' '}
                                    <span className="text-blue-500">
                                        {fullname} @{username}
                                    </span>{' '}
                                    to join the Digital Currency Revolution{' '}
                                    <span className="text-blue-500">@dksh</span>
                                    . Our mission is clear: to redistribute
                                    wealth and empower every Kenyan.
                                </div>
                                <div>
                                    To kickstart this movement and mitigate
                                    inflation, we are awarding every member a
                                    substantial 10,000,000 DKSH, with an
                                    exclusive Genesis Stimulus of 75,000 DKSH.
                                </div>
                                <div>
                                    And that's not all! You'll enjoy a monthly
                                    guarantee of 75,000 DKSH, showcasing our
                                    dedication to your financial success.
                                </div>
                                <div>
                                    Our algorithms will dynamically adjust it
                                    based on the economy's reaction. This means
                                    the amount could increase or decrease,
                                    reflecting our commitment to adapt and
                                    ensure your financial well-being in response
                                    to economic changes.
                                </div>
                                <div>
                                    <span>Referral Link: </span>
                                    <span className="text-blue-500">
                                        {`${
                                            window.location.origin
                                        }?network=${spaceToUnderscore(
                                            fullname
                                        )}@${username}&referral_code=${uid}`}
                                    </span>
                                </div>
                            </div>
                        );
                    }}
                    loader={{
                        width: 'w-[24px]',
                        border: 'border-t-gray-500 border-l-red-500 border-b-green-500',
                    }}
                    hasLoader={true}
                    fill={'fill-red-500'}
                    width={'w-[28px]'}
                    className="p-3 border-4 border-t-gray-500 border-l-red-500 border-b-green-500 hero-pattern-glamorous-80 bg-white shadow-lg rounded-full"
                >
                    <path d="M6.5 0A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0zm3 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5z" />
                    <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1A2.5 2.5 0 0 1 9.5 5h-3A2.5 2.5 0 0 1 4 2.5zM10 8a1 1 0 1 1 2 0v5a1 1 0 1 1-2 0zm-6 4a1 1 0 1 1 2 0v1a1 1 0 1 1-2 0zm4-3a1 1 0 0 1 1 1v3a1 1 0 1 1-2 0v-3a1 1 0 0 1 1-1" />
                </Svg>
                <div className="font-semibold break-all italic underline text-blue-500 text-sm md:text-base">
                    Refer a friend and earn up to 2,800 DKSH when they sign-up.
                </div>
            </div>
        </div>
    );
};
