import { useRef } from 'react';
import { useLinkClick } from '../hooks';
import { Loader } from './Loader';

export const Svg = ({
    className,
    width,
    children,
    viewBox,
    padding,
    fill,
    animate,
    loader,
    hasLoader,
    to,
    disabled,
    onClick,
    ...args
}) => {
    const { navigateTo } = useLinkClick();
    const svgRef = useRef();

    return (
        <div className={`relative group ${className}`} {...args}>
            <div
                className={`${width || 'w-[24px]'} ${
                    hasLoader && 'group-active:opacity-0'
                }`}
            >
                <div className="aspect-w-1 aspect-h-1 relative">
                    <svg
                        viewBox={`${viewBox || '0 0 16 16'}`}
                        className={`iNOS-display-left w-full h-full ${padding} ${fill} ${animate}`}
                        ref={svgRef}
                    >
                        {children}
                    </svg>
                </div>
            </div>
            {hasLoader && (
                <div className="iNOS-display-middle w-full h-full flex items-center justify-center opacity-0 group-active:opacity-100">
                    <Loader {...loader} />
                </div>
            )}
            <div
                onClick={() => {
                    if (disabled === true) return;

                    to && navigateTo(to);
                }}
                {...{ onClick }}
                className="iNOS-display-middle w-full h-full z-50"
            ></div>
        </div>
    );
};
