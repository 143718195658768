import { Route, Routes } from 'react-router-dom';
import * as screens from './screens';
import { Provider } from './providers';
import { Mainlayout } from './components';

export const Dksh = () => {
    return (
        <Provider>
            <Mainlayout>
                <Routes>
                    {Object.entries({ ...screens }).map(([key, value]) => (
                        <Route {...{ ...value, key }} />
                    ))}
                </Routes>
            </Mainlayout>
        </Provider>
    );
};
