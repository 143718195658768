import { useEffect, useRef } from 'react';
import { useAceEditor } from '../../../../../hooks';

export const DocumentEditor = ({
    document,
    height,
    disable,
    controls,
    name,
}) => {
    const { ace } = useAceEditor();

    const editorRef = useRef();

    useEffect(() => {
        if (!ace) return;

        const codeEditor = ace.edit(editorRef.current);

        (() => {
            codeEditor.setTheme('ace/theme/tomorrow');
            codeEditor.session.setMode('ace/mode/javascript');
            codeEditor.setOptions({
                fontSize: '12pt',
                enableBasicAutocompletion: true,
                enableSnippets: true,
                enableLiveAutocompletion: false,
            });
            codeEditor.setValue(JSON.stringify({ ...document }, null, 4));
            codeEditor.clearSelection();
            codeEditor.setReadOnly(disable);
        })();
    }, [ace, document]);

    return (
        <div
            className={`w-full ${
                height ? height : 'h-full'
            } overflow-y-scroll hide-scroll border-8 border-gray-200 bg-gray-200 relative`}
        >
            <div className="iNOS-display-middle w-full h-full border-4 border-gray-300 rounded-3xl overflow-hidden">
                <div className="w-full h-full" ref={editorRef}></div>
            </div>
            {controls === true && (
                <div className="iNOS-display-bottomright p-5">Hello</div>
            )}
        </div>
    );
};
