import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Dksh } from './Dksh';
import { BrowserRouter } from 'react-router-dom';

import ErrorBoundary from './components/ErrorBoundry';
import { ErrorBoundaryFallbackUi } from './components';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <ErrorBoundary fallback={<ErrorBoundaryFallbackUi />}>
        <BrowserRouter>
            <Dksh />
        </BrowserRouter>
    </ErrorBoundary>
);
