import { createContext, useEffect, useRef, useState } from 'react';
import { Svg } from '../components';
import { useAnime } from '../hooks';

export const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
    const [message, setMessage] = useState();
    const [error, setError] = useState();
    const [copy, setCopy] = useState();
    const [statement, setStatement] = useState();
    const [_htmlError, set_htmlError] = useState();
    const [_htmlMessage, set_htmlMessage] = useState();

    const { anime } = useAnime();

    const clear = () => {
        setError();
        setMessage();
        setCopy();
        setStatement();
        set_htmlError();
        set_htmlMessage();
    };

    const setter = {
        setMessage: (message) => {
            clear();
            setMessage(message);
        },
        setError: (error) => {
            clear();

            setError(error);
        },
        setCopy: (copy) => {
            clear();

            setCopy(copy);
        },
        setStatement: (statement) => {
            clear();

            setStatement(statement);
        },
        set_htmlError: (_htmlError) => {
            clear();

            set_htmlError(_htmlError);
        },
        set_htmlMessage: (_htmlMessage) => {
            clear();

            set_htmlMessage(_htmlMessage);
        },
    };

    const ToastContainer = () => {
        const containerRef = useRef();

        const copyToClipboard = () => {
            if (containerRef.current) {
                const textToCopy = containerRef.current.innerText;

                navigator.clipboard
                    .writeText(textToCopy)
                    .then(() => {
                        setter.setMessage(
                            'Text copied to clipboard successfully!'
                        );
                    })
                    .catch(() => {
                        setter.setError('Unable to copy text to clipboard.');
                    });
            }
        };

        const bellPathRef = useRef(null);

        useEffect(() => {
            return anime({
                targets: bellPathRef.current,
                rotate: [-10, 10],
                duration: 800,
                easing: 'easeInOutQuad',
                loop: true,
                direction: 'alternate',
            });
        }, [copy]);

        return (
            (error ||
                message ||
                copy ||
                statement ||
                _htmlError ||
                _htmlMessage) && (
                <div className="iNOS-display-topright flex flex-col px-2 my-5 md:m-5 space-y-5 z-[6000] w-full max-w-xl">
                    <div className="flex justify-end items-center">
                        <div className="flex flex-row space-x-5">
                            <div
                                {...(copy && {
                                    onClick: copyToClipboard,
                                    ref: bellPathRef,
                                })}
                                className={`p-2 ${
                                    ((message ||
                                        copy ||
                                        statement ||
                                        _htmlMessage) &&
                                        'bg-green-500') ||
                                    ((error || _htmlError) && 'bg-red-500')
                                } text-gray-100 rounded-full shadow-lg hero-pattern-glamorous-60 group border-4 border-t-gray-500 border-l-red-500 border-b-green-500`}
                            >
                                <Svg
                                    width={'w-[26px]'}
                                    fill={`fill-gray-200`}
                                    loader={{
                                        width: 'w-[20px]',
                                        border: 'border-t-gray-500 border-l-red-500 border-b-white',
                                    }}
                                    {...(copy && {
                                        hasLoader: true,
                                        disabled: false,
                                        onClick: copyToClipboard,
                                    })}
                                >
                                    {(message || _htmlMessage) && (
                                        <>
                                            <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
                                            <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
                                        </>
                                    )}
                                    {(error || _htmlError) && (
                                        <>
                                            <path d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435zm1.4.7a.495.495 0 0 0-.7 0L1.134 7.65a.495.495 0 0 0 0 .7l6.516 6.516a.495.495 0 0 0 .7 0l6.516-6.516a.495.495 0 0 0 0-.7L8.35 1.134z" />
                                            <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                        </>
                                    )}
                                    {copy && (
                                        <>
                                            <path d="M10 1.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5zm-5 0A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5v1A1.5 1.5 0 0 1 9.5 4h-3A1.5 1.5 0 0 1 5 2.5zm-2 0h1v1A2.5 2.5 0 0 0 6.5 5h3A2.5 2.5 0 0 0 12 2.5v-1h1a2 2 0 0 1 2 2V14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3.5a2 2 0 0 1 2-2" />
                                        </>
                                    )}
                                    {statement && (
                                        <>
                                            <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2.5a2 2 0 0 0-1.6.8L8 14.333 6.1 11.8a2 2 0 0 0-1.6-.8H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                                            <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5M3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6m0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5" />
                                        </>
                                    )}
                                </Svg>
                            </div>
                            <div
                                className={`p-2 text-gray-100 hero-pattern-glamorous-60 bg-white hero-pattern-glamorous-90 rounded-full border-4 border-t-gray-500 border-l-red-500 border-b-green-500 shadow-lg group`}
                                onClick={() => {
                                    clear();
                                }}
                            >
                                <Svg
                                    width={'w-[26px]'}
                                    fill={`${
                                        ((message ||
                                            copy ||
                                            statement ||
                                            _htmlMessage) &&
                                            'fill-green-500') ||
                                        ((error || _htmlError) &&
                                            'fill-red-500')
                                    }`}
                                    loader={{
                                        width: 'w-[20px]',
                                        border: 'border-t-gray-500 border-l-red-500 border-b-green-500',
                                    }}
                                    hasLoader={true}
                                    padding={'p-1'}
                                >
                                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                                </Svg>
                            </div>
                        </div>
                    </div>
                    <div className="w-full border p-5 py-0 rounded-3xl hero-pattern-glamorous-80 shadow-lg bg-white overflow-hidden">
                        <div
                            className={`w-full overflow-y-scroll hide-scroll ${
                                (error || _htmlError) && 'bg-red-500'
                            } ${
                                (message ||
                                    copy ||
                                    statement ||
                                    _htmlMessage) &&
                                'bg-green-500'
                            } bg-black max-h-[250px] border-4 border-t-gray-500 border-l-red-500 text-gray-200 border-b-green-500 shadow-lg rounded-3xl`}
                        >
                            <div className="w-full h-full py-5 px-3">
                                <div
                                    ref={containerRef}
                                    className={`col-span-4 break-all italic rounded-3xl px-2 text-base font-medium w-full flex items-center h-full overflow-scroll hide-scroll`}
                                    {...((_htmlError || _htmlMessage) && {
                                        dangerouslySetInnerHTML: {
                                            __html: _htmlError || _htmlMessage,
                                        },
                                    })}
                                >
                                    {error || message || copy || statement}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        );
    };

    return (
        <ToastContext.Provider
            value={{
                ToastContainer,
                ...setter,
                clear,
            }}
        >
            {children}
        </ToastContext.Provider>
    );
};
