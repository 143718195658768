import { addCommasToNumber, truncateToTwoDecimalPlaces } from '../utils';

export const ReceiveMoneyMessageTemplate = ({
    statement,
    isSenderDKSHSavingsAccount,
    hasReferral,
}) => {
    return (
        <div>
            You have received{' '}
            {addCommasToNumber(truncateToTwoDecimalPlaces(statement?.amount))}{' '}
            DKSH from {isSenderDKSHSavingsAccount && 'your'}
            {hasReferral && 'our'}{' '}
            <span className="text-blue-500">
                {statement?.sender?.fullname}{' '}
                {`@${statement?.sender?.username}`}
            </span>
            {hasReferral && (
                <span>
                    {' '}
                    for referring{' '}
                    <span>
                        <span className="text-blue-500">
                            {statement?.referred?.fullname} @
                            {statement?.referred?.username}
                        </span>
                    </span>
                </span>
            )}
            . Thank you for using our services. Your new balance is{' '}
            {addCommasToNumber(truncateToTwoDecimalPlaces(statement?.balance))}{' '}
            DKSH.
        </div>
    );
};
