import { BackendProvider } from './BackendProvider';
import { GrabProvider } from './GrabProvider';
import { LinkProvider } from './LinkProvider';
import { ScreenLoaderProvider } from './ScreenLoaderProvider';
import { SharedAccessProvider } from './SharedAccessProvider';
import { ToastProvider } from './ToastProvider';

export const Provider = ({ children }) => {
    return (
        <LinkProvider>
            <BackendProvider>
                <ToastProvider>
                    <SharedAccessProvider>
                        <GrabProvider>
                            <ScreenLoaderProvider>
                                {children}
                            </ScreenLoaderProvider>
                        </GrabProvider>
                    </SharedAccessProvider>
                </ToastProvider>
            </BackendProvider>
        </LinkProvider>
    );
};

export * from './LinkProvider';
export * from './ToastProvider';
export * from './ScreenLoaderProvider';
export * from './SharedAccessProvider';
export * from './BackendProvider';
export * from './GrabProvider';
