import { useEffect, useState } from 'react';
import {
    ActiveButton,
    Center,
    Form,
    Input,
    Link,
    Logo,
} from '../../components';
import { keysToEntries, sendArpToServer } from '../../utils';
import { useAuth, useBackend, useLinkClick } from '../../hooks';

const VerifyComponent = () => {
    const [input, setInput] = useState({});
    const {
        isMember,
        isAnonymous,
        isTwoFactorAuthentication,
        isPasswordReset,
        isAuth,
    } = useAuth();

    const [manager, setManager] = useState({});

    const { navigateTo } = useLinkClick();
    const { post } = useBackend();

    useEffect(() => {
        isTwoFactorAuthentication === true &&
            setManager({ isTwoFactorAuthentication });

        isAnonymous === true && setManager({ isAnonymous });
    }, [isTwoFactorAuthentication]);

    useEffect(() => {
        if (isMember) return navigateTo('/');
        if (isPasswordReset) return navigateTo('/reset');
        if (
            manager.isTwoFactorAuthentication &&
            isTwoFactorAuthentication !== true
        )
            return navigateTo('/signin');
        if (manager.isAnonymous && isAnonymous !== true)
            return navigateTo('/signup');

        if (isAuth !== true) return navigateTo('/signup');
    }, [isMember, isAnonymous, isTwoFactorAuthentication, isPasswordReset]);

    if ([isMember, isPasswordReset, isAuth !== true].includes(true)) return;

    return (
        <div className="h-full w-full flex flex-col items-center justify-center py-5 md:py-10">
            <div className="md:max-w-[600px] w-full md:border p-5 md:p-7 bg-white rounded-2xl space-y-5">
                <Form
                    className="space-y-5"
                    onSubmit={() => {
                        isAnonymous &&
                            post({
                                exec: 'VerifyAccountRecoveryPassword',
                                client: {
                                    account: {
                                        ...input,
                                    },
                                },
                            });

                        isTwoFactorAuthentication &&
                            post({
                                exec: 'TwoFactorAuthentication',
                                client: {
                                    account: {
                                        ...input,
                                    },
                                },
                            });
                    }}
                >
                    <div className="w-full flex items-center justify-center">
                        <Logo />
                    </div>
                    {Object.entries({ ...keysToEntries('arp') }).map(
                        ([key], i) => (
                            <Input
                                label={['arp'].includes(key) ? 'A.R.P' : key}
                                required={true}
                                type={key}
                                name={key}
                                key={i}
                                autoComplete="off"
                                onChange={(e) => {
                                    sendArpToServer({ e, input, setInput })();
                                }}
                            />
                        )
                    )}
                    <div className="flex flex-row space-x-2 text-sm justify-between">
                        <span className="text-blue-500 font-bold active:underline">
                            <Link
                                onClick={() => {
                                    isAnonymous && post({ exec: 'UndoSignup' });
                                    isTwoFactorAuthentication &&
                                        post({ exec: 'UndoSignin' });
                                }}
                            >
                                Cancel{' '}
                                {(isAnonymous && 'A.R.P Verification?') ||
                                    (isTwoFactorAuthentication &&
                                        'two factor authentication?')}
                            </Link>
                        </span>
                    </div>
                    <div className="flex flex-row justify-center items-center w-full">
                        <ActiveButton
                            type="submit"
                            className="p-5 bg-black w-full rounded-full hero-pattern-glamorous-60 text-red-700 font-black"
                            loader={{
                                width: 'w-[28px]',
                                border: 'border-t-white border-l-red-500 border-b-green-500',
                            }}
                        >
                            <Center>
                                {(isAnonymous && 'Verify A.R.P') ||
                                    (isTwoFactorAuthentication && 'Sign in')}
                            </Center>
                        </ActiveButton>
                    </div>
                </Form>
                <div className="flex flex-row space-x-2 text-sm justify-between md:hidden">
                    <span className="text-blue-500 font-bold active:underline">
                        <Link
                            onClick={() => {
                                isAnonymous &&
                                    post({
                                        exec: 'GenerateAccountRecoveryPassword',
                                    });

                                isTwoFactorAuthentication &&
                                    post({ exec: 'UndoSignin' });
                            }}
                        >
                            {(isAnonymous && "Didn't Receive A.R.P?") ||
                                (isTwoFactorAuthentication &&
                                    'Not your account?')}
                        </Link>
                    </span>
                </div>
                <div className="md:flex hidden flex-row space-x-2">
                    <div className="font-bold text-gray-800">
                        {(isAnonymous && "Didn't Receive A.R.P?") ||
                            (isTwoFactorAuthentication && 'Not your account?')}
                    </div>
                    <ActiveButton
                        loader={{
                            width: 'w-[24px]',
                            border: 'border-t-gray-500 border-l-red-500 border-b-green-500',
                        }}
                        onClick={() => {
                            isAnonymous &&
                                post({
                                    exec: 'GenerateAccountRecoveryPassword',
                                });

                            isTwoFactorAuthentication &&
                                post({ exec: 'UndoSignin' });
                        }}
                    >
                        <div className="text-blue-500 font-bold">
                            {(isAnonymous && 'Download.') ||
                                (isTwoFactorAuthentication && 'Sign in.')}
                        </div>
                    </ActiveButton>
                </div>
            </div>
        </div>
    );
};

export const VerfiyPage = { path: '/verify', element: <VerifyComponent /> };
