export const parseSearchParams = (search) => {
    const searchParams = new URLSearchParams(search);
    const paramsObject = {};

    for (const [key, value] of searchParams.entries()) {
        paramsObject[key] = value;
    }

    return paramsObject;
};
