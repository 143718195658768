import { useState } from 'react';
import { Svg, ContentLoader, ActiveButton } from '../../../../';
import { useDatabase } from '../../../../../hooks';
import { CollectionHeader } from './CollectionsHeader';
import { DatabaseCollection } from './DatabaseCollection';
import { DatabaseCache } from './DatabaseCache';
import { PreviewEditor } from '../PreviewEditor';
import { CachesHeader } from './CachesHeader';

export const StellarDatabaseHeader = () => {
    const {
        databaseStats: { name, ...databaseStats },
    } = useDatabase();

    const [pointer, setPointer] = useState({ active: 'stats' });

    return (
        <div className="relative">
            {!name && (
                <div className="iNOS-display-middle w-full h-full z-50 bg-white flex items-center justify-center">
                    <ContentLoader width={'w-[100px]'} />
                </div>
            )}
            <div className="hide-scroll flex items-center flex-row bg-red-800 hero-pattern-hideout w-full overflow-x-scroll">
                <div
                    onClick={() => {
                        setPointer({ active: 'stats' });
                    }}
                    className={`py-5 ${
                        ['stats'].includes(pointer.active) !== true &&
                        'animate-pulse'
                    }  active:animate-none px-5 space-x-4 w-full flex flex-row items-center active:bg-red-900 active:text-red-600 active:hero-pattern-hideout`}
                >
                    <div>
                        <Svg
                            fill={'fill-red-500 active:fill-red-600'}
                            width={'w-[24px]'}
                        >
                            <path d="M3.904 1.777C4.978 1.289 6.427 1 8 1s3.022.289 4.096.777C13.125 2.245 14 2.993 14 4s-.875 1.755-1.904 2.223C11.022 6.711 9.573 7 8 7s-3.022-.289-4.096-.777C2.875 5.755 2 5.007 2 4s.875-1.755 1.904-2.223" />
                            <path d="M2 6.161V7c0 1.007.875 1.755 1.904 2.223C4.978 9.71 6.427 10 8 10s3.022-.289 4.096-.777C13.125 8.755 14 8.007 14 7v-.839c-.457.432-1.004.751-1.49.972C11.278 7.693 9.682 8 8 8s-3.278-.307-4.51-.867c-.486-.22-1.033-.54-1.49-.972" />
                            <path d="M2 9.161V10c0 1.007.875 1.755 1.904 2.223C4.978 12.711 6.427 13 8 13s3.022-.289 4.096-.777C13.125 11.755 14 11.007 14 10v-.839c-.457.432-1.004.751-1.49.972-1.232.56-2.828.867-4.51.867s-3.278-.307-4.51-.867c-.486-.22-1.033-.54-1.49-.972" />
                            <path d="M2 12.161V13c0 1.007.875 1.755 1.904 2.223C4.978 15.711 6.427 16 8 16s3.022-.289 4.096-.777C13.125 14.755 14 14.007 14 13v-.839c-.457.432-1.004.751-1.49.972-1.232.56-2.828.867-4.51.867s-3.278-.307-4.51-.867c-.486-.22-1.033-.54-1.49-.972" />
                        </Svg>
                    </div>
                    <div className="font-semibold flex-grow text-red-500 flex flex-row">
                        {name || 'database'}
                    </div>
                </div>
            </div>
            <CollectionHeader {...{ setPointer, pointer }} />
            <div className="h-[90vh] relative w-full">
                <div className="iNOS-display-middle w-full h-full bg-white">
                    {
                        {
                            stats: <PreviewEditor data={databaseStats} />,
                            collection: <DatabaseCollection {...pointer} />,
                            cache: <DatabaseCache {...pointer} />,
                        }[pointer.active]
                    }
                </div>
            </div>
            <CachesHeader {...{ setPointer, pointer }} />
        </div>
    );
};
