import { useEffect, useRef, useState } from 'react';
import { useBackend, useDatabase } from '../../../../../hooks';
import { ContentLoader } from '../../../../ContentLoader';
import { DocumentEditor } from './DocumentEditor';
import { debounce, elementListener, scroll } from '../../../../../utils';
import { Loader } from '../../../../Loader';

export const DatabaseCollection = ({ name }) => {
    const { post } = useBackend();
    const {
        databaseCollectionDocuments: { documents, stats },
    } = useDatabase();
    const scrollEditorContainerRef = useRef();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(documents.length !== stats?.documents.count);
    }, [documents, stats]);

    useEffect(() => {
        scrollEditorContainerRef.current.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [name]);

    useEffect(() => {
        post(
            {
                exec: 'DatabaseCollectionDocuments',
                client: {
                    name,
                    items: 0,
                },
            },
            { auht: true, loader: false }
        );
    }, [name]);

    useEffect(() => {
        return elementListener(scrollEditorContainerRef)(
            'scroll',
            debounce(
                scroll(({ yScrollPercentage }) => {
                    yScrollPercentage > 40 &&
                        post(
                            {
                                exec: 'DatabaseCollectionDocuments',
                                client: {
                                    name,
                                    items: documents.length,
                                },
                            },
                            {
                                auht: true,
                                loader: false,
                                lock:
                                    documents.length === stats.documents.count,
                            }
                        );
                }),
                300
            )
        );
    });

    return [stats, ...documents].length > 0 ? (
        <div
            ref={scrollEditorContainerRef}
            className="w-full h-full overflow-y-scroll scrollbar-thin"
        >
            <DocumentEditor
                {...{
                    document: {
                        [name]: { ...stats },
                    },
                    height: documents.length && 'h-[60vh]',
                    disable: true,
                }}
            />
            {[...documents].map((document, i) => (
                <DocumentEditor
                    key={i}
                    {...{
                        document,
                        name,
                        controls: true,
                    }}
                />
            ))}
            {loading && (
                <div className="flex items-center justify-center py-5">
                    <Loader
                        border={
                            'border-t-gray-500 border-l-red-500 border-b-green-500'
                        }
                    />
                </div>
            )}
        </div>
    ) : (
        <div className="w-full h-full flex items-center justify-center">
            <ContentLoader width={'w-[100px]'} />
        </div>
    );
};
