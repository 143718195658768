import { RenderHub } from '../utils';
import * as managers from '../components/managers';
import { useAuth, useLinkClick, useSearchParams } from '../hooks';
import { Notfound } from '../components';
import { useEffect } from 'react';

const ManagerComponent = () => {
    const { q } = useSearchParams();

    const { isMember } = useAuth();
    const { navigateTo } = useLinkClick();

    useEffect(() => {
        if (isMember !== true) return navigateTo('/');
    }, [isMember]);

    if (isMember !== true) return;

    return RenderHub(
        managers,
        q
    )(() => {
        return <Notfound />;
    });
};

export const ManagerPage = { path: '/manager', element: <ManagerComponent /> };
