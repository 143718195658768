import { useEffect, useState } from 'react';
import { keysToEntries, sendArpToServer } from '../../../../utils';
import { Svg, Input, Form, Center, ActiveButton } from '../../..';
import { useBackend } from '../../../../hooks';

export const Maintenance = () => {
    const [input, setInput] = useState({});
    const { post } = useBackend();

    return (
        <div className="w-full max-w-screen-md m-auto">
            <div className="space-y-3">
                <div className="w-full relative space-y-5 md:space-y-0 md:border md:px-5 md:rounded-3xl md:hero-pattern-glamorous-80 md:shadow-lg md:bg-white overflow-hidden">
                    <div className="w-full flex items-center justify-center">
                        <div className="flex items-center justify-center space-x-5 md:py-3">
                            <div>
                                <Svg fill={'fill-red-500'} width={'w-[28px]'}>
                                    <path d="M1 0 0 1l2.2 3.081a1 1 0 0 0 .815.419h.07a1 1 0 0 1 .708.293l2.675 2.675-2.617 2.654A3.003 3.003 0 0 0 0 13a3 3 0 1 0 5.878-.851l2.654-2.617.968.968-.305.914a1 1 0 0 0 .242 1.023l3.27 3.27a.997.997 0 0 0 1.414 0l1.586-1.586a.997.997 0 0 0 0-1.414l-3.27-3.27a1 1 0 0 0-1.023-.242L10.5 9.5l-.96-.96 2.68-2.643A3.005 3.005 0 0 0 16 3q0-.405-.102-.777l-2.14 2.141L12 4l-.364-1.757L13.777.102a3 3 0 0 0-3.675 3.68L7.462 6.46 4.793 3.793a1 1 0 0 1-.293-.707v-.071a1 1 0 0 0-.419-.814zm9.646 10.646a.5.5 0 0 1 .708 0l2.914 2.915a.5.5 0 0 1-.707.707l-2.915-2.914a.5.5 0 0 1 0-.708M3 11l.471.242.529.026.287.445.445.287.026.529L5 13l-.242.471-.026.529-.445.287-.287.445-.529.026L3 15l-.471-.242L2 14.732l-.287-.445L1.268 14l-.026-.529L1 13l.242-.471.026-.529.445-.287.287-.445.529-.026z" />
                                </Svg>
                            </div>
                            <div className="font-bold text-red-500 text-lg">
                                Maintenance
                            </div>
                        </div>
                    </div>
                    <div className="w-full relative hero-pattern-jigsaw-90 border-4 border-t-gray-500 border-l-red-500 border-b-green-500 shadow-lg rounded-3xl bg-white px-5 py-10">
                        <Form
                            className="space-y-5"
                            onSubmit={() => {
                                post(
                                    {
                                        exec: 'Reload',
                                        client: { account: { ...input } },
                                    },
                                    { auth: true }
                                );
                            }}
                        >
                            {Object.entries({
                                ...keysToEntries('arp'),
                            }).map(([key], i) => (
                                <Input
                                    label={
                                        ['arp'].includes(key) ? 'A.R.P' : key
                                    }
                                    required={true}
                                    type={key}
                                    name={key}
                                    arpClassName={
                                        'file:bg-red-700 file:text-yellow-500 text-red-600 file:shadow-lg file:active:shadow-none'
                                    }
                                    labelClassName={'text-red-600'}
                                    labelDisplay
                                    key={i}
                                    space={{ y: 'space-y-3' }}
                                    displayFileNameAsLabel
                                    autoComplete="off"
                                    onChange={(e) => {
                                        sendArpToServer({
                                            e,
                                            input,
                                            setInput,
                                        })();
                                    }}
                                />
                            ))}
                            <div className="flex flex-row justify-center items-center w-full">
                                <ActiveButton
                                    type="submit"
                                    className="p-5 bg-red-700 w-full rounded-full hero-pattern-glamorous-60 text-yellow-500 font-black"
                                    loader={{
                                        width: 'w-[28px]',
                                        border: 'border-t-gray-500 border-l-white border-b-green-500',
                                    }}
                                >
                                    <Center>Reload</Center>
                                </ActiveButton>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
};
