export const base64ToBuffer = (base64String) => {
    const binaryString = atob(base64String);
    const buffer = new Uint8Array(binaryString.length);

    for (let i = 0; i < binaryString.length; i++) {
        buffer[i] = binaryString.charCodeAt(i);
    }

    return buffer;
};
