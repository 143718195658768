import { useEffect, useState } from 'react';
import { ActiveButton, Center, Form, Input, Logo } from '../../components';
import { keysToEntries, sendArpToServer } from '../../utils';
import {
    useAuth,
    useBackend,
    useLinkClick,
    useScroll,
    useToast,
} from '../../hooks';
import { Link } from 'react-router-dom';

export const ResetComponent = () => {
    const [input, setInput] = useState({});
    const { navigateTo } = useLinkClick();
    const { triggerScrollTop } = useScroll();
    const {
        isMember,
        isAnonymous,
        isPasswordReset,
        isTwoFactorAuthentication,
        username,
    } = useAuth();
    const { setError } = useToast();
    const { post } = useBackend();

    useEffect(() => {
        triggerScrollTop();
    }, [isPasswordReset]);

    useEffect(() => {
        if (isMember) return navigateTo('/');
        if ([isAnonymous, isTwoFactorAuthentication].includes(true))
            return navigateTo('/verify');
    }, [isMember, isAnonymous, isTwoFactorAuthentication]);

    if ([isMember, isAnonymous, isTwoFactorAuthentication].includes(true))
        return;

    return (
        <div className="h-full w-full flex flex-col items-center justify-center py-5 md:py-10">
            <div className="md:max-w-[600px] w-full md:border p-5 md:p-7 bg-white rounded-2xl space-y-5">
                <Form
                    className="space-y-5"
                    onSubmit={() => {
                        isPasswordReset
                            ? (() => {
                                  if (
                                      [input?.password].includes(
                                          { ...input }['confirm password']
                                      ) !== true
                                  )
                                      return setError(
                                          'Passwords do not match.'
                                      );

                                  post({
                                      exec: 'ResetPassword',
                                      client: {
                                          account: {
                                              ...input,
                                          },
                                      },
                                  });
                              })()
                            : post({
                                  exec: 'ForgotPassword',
                                  client: {
                                      account: { ...input },
                                  },
                              });
                    }}
                >
                    <div className="w-full flex items-center justify-center">
                        <Logo />
                    </div>
                    {Object.entries({
                        ...keysToEntries('username'),
                        ...(isPasswordReset && {
                            username,
                            ...keysToEntries(
                                'arp',
                                'password',
                                'confirm password'
                            ),
                        }),
                    }).map(([key, value], i) => (
                        <Input
                            required={true}
                            label={['arp'].includes(key) ? 'A.R.P' : key}
                            type={
                                ['password', 'confirm password'].includes(key)
                                    ? 'text'
                                    : key
                            }
                            name={key}
                            key={i}
                            autoComplete="off"
                            disabled={
                                isPasswordReset && ['username'].includes(key)
                            }
                            onChange={(e) => {
                                sendArpToServer({ e, input, setInput })(() => {
                                    setInput({
                                        ...input,
                                        [e.target.name]: e.target.value,
                                    });
                                });
                            }}
                            {...(isPasswordReset &&
                                ['username'].includes(key) && {
                                    placeholder: value,
                                })}
                        />
                    ))}
                    <div className="flex flex-row space-x-2 text-sm justify-between">
                        <span className="text-blue-500 font-bold active:underline">
                            <Link
                                onClick={(e) => {
                                    e.preventDefault();

                                    isPasswordReset
                                        ? post({ exec: 'UndoForgotPassword' })
                                        : navigateTo('/signin');
                                }}
                            >
                                {isPasswordReset
                                    ? 'Cancel password reset?'
                                    : 'Already have an account?'}
                            </Link>
                        </span>
                    </div>
                    <div className="flex flex-row justify-center items-center w-full">
                        <ActiveButton
                            type="submit"
                            className="p-5 bg-black w-full rounded-full hero-pattern-glamorous-60 text-red-700 font-black"
                            loader={{
                                width: 'w-[28px]',
                                border: 'border-t-white border-l-red-500 border-b-green-500',
                            }}
                        >
                            <Center>
                                {isPasswordReset
                                    ? 'Reset Password'
                                    : 'Forgot Password'}
                            </Center>
                        </ActiveButton>
                    </div>
                </Form>
                {isPasswordReset ? (
                    <>
                        <div className="flex flex-row space-x-2 text-sm justify-between md:hidden">
                            <span className="text-blue-500 font-bold active:underline">
                                <Link
                                    onClick={() => {
                                        post({ exec: 'UndoForgotPassword' });
                                    }}
                                >
                                    Not your account?
                                </Link>
                            </span>
                        </div>
                        <div className="md:flex flex-row space-x-2 hidden">
                            <div className="font-bold text-gray-800">
                                Not your account?
                            </div>
                            <ActiveButton
                                loader={{
                                    width: 'w-[24px]',
                                    border: 'border-t-gray-500 border-l-red-500 border-b-green-500',
                                }}
                                onClick={() => {
                                    post({ exec: 'UndoForgotPassword' });
                                }}
                            >
                                <div className="text-blue-500 font-bold">
                                    Forgot password.
                                </div>
                            </ActiveButton>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="flex flex-row space-x-2 text-sm justify-between md:hidden">
                            <span className="text-blue-500 font-bold active:underline">
                                <Link to={'/signup'}>
                                    {' '}
                                    Don't have an account?
                                </Link>
                            </span>
                        </div>
                        <div className="md:flex hidden flex-row space-x-2">
                            <div className="font-bold text-gray-800">
                                Don't have an account?
                            </div>
                            <ActiveButton
                                loader={{
                                    width: 'w-[24px]',
                                    border: 'border-t-gray-500 border-l-red-500 border-b-green-500',
                                }}
                                onClick={() => {
                                    navigateTo('/signup');
                                }}
                            >
                                <div className="text-blue-500 font-bold">
                                    Sign up.
                                </div>
                            </ActiveButton>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export const ResetPage = { path: '/reset', element: <ResetComponent /> };
