import { createContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

export const LinkContext = createContext();

export const LinkProvider = ({ children }) => {
    const [to, setTo] = useState();
    const linkRef = useRef();

    useEffect(() => {
        to && linkRef.current.click();

        return () => {
            setTo();
        };
    }, [to]);

    return (
        <LinkContext.Provider
            value={{
                navigateTo: (to) => {
                    setTo(to);
                },
            }}
        >
            <Link className="hidden" ref={linkRef} {...{ to }} />
            {children}
        </LinkContext.Provider>
    );
};
