import { ManagerPreviewCard } from '.';
import { useGrab } from '../hooks/useGrab';

export const ManagersProvider = () => {
    const { managers } = useGrab();

    return (
        <div className="w-full max-w-screen-md m-auto px-3">
            <div className="w-full relative space-y-5 md:space-y-0 md:border md:px-5 md:rounded-3xl md:hero-pattern-glamorous-80 md:shadow-lg md:bg-white overflow-hidden">
                <div className="w-full relative hero-pattern-jigsaw-90 border-4 border-t-gray-500 border-l-red-500 border-b-green-500 shadow-lg rounded-3xl bg-white">
                    <div className="w-full invisible">
                        <div className="w-full md:grid gap-5 grid-cols-3 hidden">
                            {Array.from({ length: 9 }).map((_, i) => (
                                <ManagerPreviewCard
                                    key={i}
                                    {...{ manager: [...managers][i] }}
                                />
                            ))}
                        </div>
                        <div className="w-full md:hidden gap-5 grid grid-cols-1">
                            {Array.from({ length: 3 }).map((_, i) => (
                                <ManagerPreviewCard
                                    key={i}
                                    {...{ manager: [...managers][i] }}
                                />
                            ))}
                        </div>
                        <div>Scroll indicator</div>
                    </div>
                    <div className="iNOS-display-middle w-full h-full">
                        <div className="w-full h-full grid grid-cols-1 md:grid-cols-3 gap-5 p-5 hide-scroll overflow-y-scroll">
                            {[...managers].map((manager, i) => (
                                <ManagerPreviewCard
                                    key={i}
                                    {...{
                                        manager,
                                    }}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
