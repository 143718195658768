import { useEffect, useRef, useState } from 'react';
import { useBackend, useDatabase } from '../../../../../hooks';
import { ContentLoader } from '../../../../ContentLoader';
import { DocumentEditor } from './DocumentEditor';
import { debounce, elementListener, scroll } from '../../../../../utils';
import { Loader } from '../../../../Loader';

export const DatabaseCache = ({ name }) => {
    const { post } = useBackend();
    const {
        databaseCacheData: { data, stats },
    } = useDatabase();
    const scrollEditorContainerRef = useRef();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(data.length !== stats?.count);
    }, [data, stats]);

    useEffect(() => {
        scrollEditorContainerRef.current.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [name]);

    useEffect(() => {
        post(
            {
                exec: 'DatabaseCacheData',
                client: {
                    name,
                    items: 0,
                },
            },
            { auht: true, loader: false }
        );
    }, [name]);

    useEffect(() => {
        return elementListener(scrollEditorContainerRef)(
            'scroll',
            debounce(
                scroll(({ yScrollPercentage }) => {
                    yScrollPercentage > 40 &&
                        post(
                            {
                                exec: 'DatabaseCacheData',
                                client: {
                                    name,
                                    items: data.length,
                                },
                            },
                            {
                                auht: true,
                                loader: false,
                                lock: data.length === stats.count,
                            }
                        );
                }),
                300
            )
        );
    });

    return [stats, ...data].length > 0 ? (
        <div
            ref={scrollEditorContainerRef}
            className="w-full h-full overflow-y-scroll scrollbar-thin"
        >
            <DocumentEditor
                {...{
                    document: {
                        [name]: { ...stats },
                    },
                    height: data?.length && 'h-[60vh]',
                    disable: true,
                }}
            />
            {[...data].map(([key, value], i) => (
                <DocumentEditor
                    key={i}
                    {...{
                        document: { [key]: value },
                        name,
                        disable: true,
                    }}
                />
            ))}
            {loading && (
                <div className="flex items-center justify-center py-5">
                    <Loader
                        border={
                            'border-t-gray-500 border-l-red-500 border-b-green-500'
                        }
                    />
                </div>
            )}
        </div>
    ) : (
        <div className="w-full h-full flex items-center justify-center">
            <ContentLoader width={'w-[100px]'} />
        </div>
    );
};
