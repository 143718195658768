import { cdnLink } from '../utils';
import { CircularImage } from './CircularImage';
import { Unavailable } from './Unavailable';

export const ErrorBoundaryFallbackUi = () => {
    return (
        <div className="w-full h-full relative">
            <div className="h-screen max-h-screen w-full flex flex-col overflow-y-auto">
                <div className="w-full flex items-center justify-center py-2 border-b shadow-pinterest">
                    <div className="flex flex-row items-center justify-center space-x-3">
                        <div className="rounded-full border-4 border-t-gray-500 border-l-red-500 border-b-green-500">
                            <CircularImage
                                width={'w-[54px]'}
                                className="shadow-2xl border-2 border-black rounded-full"
                                src={cdnLink('./dksh.jpg')}
                                hasLoader={true}
                                loader={{
                                    width: 'w-[24px]',
                                    border: 'border-t-white border-l-red-500 border-b-green-500',
                                }}
                                background={'bg-black hero-pattern-hideout'}
                            />
                        </div>
                        <div className="text-2xl font-bold">DKSH</div>
                    </div>
                </div>
                <div className="flex-grow min-h-1/2">
                    <Unavailable />
                </div>
                <div className="border-t-2">
                    <div className="flex h-full w-full items-center justify-center space-x-3">
                        <div className="border-4 rounded-full border-t-gray-500 hero-pattern-hideout border-l-red-500 border-b-green-500">
                            <CircularImage
                                width={'w-[60px]'}
                                className="shadow-2xl border-2 border-black rounded-full"
                                src={'./dksh.jpg'}
                                hasLoader={true}
                                loader={{
                                    width: 'w-[24px]',
                                    border: 'border-t-white border-l-red-500 border-b-green-500',
                                }}
                                background={'bg-black hero-pattern-hideout'}
                            />
                        </div>
                        <div className="py-[50px] text-2xl font-bold">
                            DKSH 2024
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
