import { useEffect } from 'react';
import { useAuth } from '../../hooks';
import { Notfound } from '../Notfound';
import {
    Maintenance,
    GeneralMetrics,
    StellarDatabase,
} from './components/dashboard/index';

export const Dashboard = () => {
    const { isAdmin } = useAuth();

    useEffect(() => {
        postMessage({});
    }, []);

    return isAdmin ? (
        <div className="w-full h-full items-center justify-center flex flex-col space-y-7 py-7 px-3">
            <GeneralMetrics />
            <StellarDatabase />
            <Maintenance />
        </div>
    ) : (
        <Notfound />
    );
};
