import { useLinkClick } from '../hooks';
import { ActiveButton } from './ActiveButton';
import { Svg } from './Svg';

export const GoHome = () => {
    const { navigateTo } = useLinkClick();

    return (
        <div className="flex flex-row space-x-5 items-center justify-center">
            <Svg
                fill={'fill-red-500'}
                width={'w-[28px]'}
                viewBox="0 0 1024 1024"
                className={
                    'p-3 border-4 border-t-gray-500 border-l-red-500 border-b-green-500 bg-white shadow-lg hero-pattern-glamorous-80 rounded-full'
                }
            >
                <path d="M946.5 505L534.6 93.4a31.93 31.93 0 0 0-45.2 0L77.5 505c-12 12-18.8 28.3-18.8 45.3 0 35.3 28.7 64 64 64h43.4V908c0 17.7 14.3 32 32 32H448V716h112v224h265.9c17.7 0 32-14.3 32-32V614.3h43.4c17 0 33.3-6.7 45.3-18.8 24.9-25 24.9-65.5-.1-90.5z"></path>
            </Svg>
            <ActiveButton
                loader={{
                    width: 'w-[28px]',
                    border: 'border-t-gray-500 border-l-red-500 border-b-green-500',
                }}
                onClick={() => {
                    navigateTo('/');
                }}
                className={'group'}
            >
                <div className="group-active:opacity-0 font-bold text-xl border text-red-500 px-3 py-2 hero-pattern-glamorous bg-white rounded-full">
                    Go Home
                </div>
            </ActiveButton>
        </div>
    );
};
