import React, { Suspense } from 'react';
import { HomeUnauthenticated, PageContentLoader } from '../components';
import { useAuth } from '../hooks';

const HomeAuthenticated = React.lazy(() =>
    import('../components/home/HomeAuthenticated')
);

const HomeComponent = () => {
    const { isMember } = useAuth();

    if (isMember)
        return (
            <Suspense fallback={<PageContentLoader />}>
                <HomeAuthenticated />
            </Suspense>
        );

    return <HomeUnauthenticated />;
};

export const HomePage = { path: '/', exact: true, element: <HomeComponent /> };
