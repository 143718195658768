import { useLocation } from 'react-router-dom';
import { useLinkClick, useScreenLoader, useScroll, useToast } from '../hooks';
import { useEffect, useState } from 'react';
import { ActiveButton } from './ActiveButton';
import { Svg } from './Svg';
import { CircularImage } from './CircularImage';
import { cdnLink, elementListener } from '../utils';

export const Mainlayout = ({ children }) => {
    const { navigateTo } = useLinkClick();
    const { pathname } = useLocation();
    const { ToastContainer } = useToast();
    const { ScreenLoader } = useScreenLoader();
    const { scrollTop, scrollContainerRef, triggerScrollTop } = useScroll();
    const [beyondReach, setBeyondReach] = useState(false);

    useEffect(() => {
        scrollContainerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }, [pathname, scrollTop]);

    useEffect(() => {
        return elementListener(scrollContainerRef)('scroll', (e) => {
            setBeyondReach(
                Math.floor(
                    (e.target.scrollTop + e.target.clientHeight) /
                        e.target.offsetHeight
                ) > 3
            );
        });
    });

    return (
        <div className="w-full h-full relative">
            <ToastContainer />
            <ScreenLoader />
            {beyondReach && (
                <div className="iNOS-display-bottomright space-y-5 m-5 mx-2 md:mx-5 z-[5000]">
                    <Svg
                        width={'w-[28px]'}
                        className={
                            'border-4 border-t-gray-500 border-l-red-500 border-b-green-500 bg-white hero-pattern-glamorous-80 shadow-lg p-3 rounded-full'
                        }
                        fill={'fill-red-500'}
                        loader={{
                            width: 'w-[24px]',
                            border: 'border-t-gray-500 border-l-red-500 border-b-green-500',
                        }}
                        hasLoader={true}
                        viewBox="0 0 970.504 970.503"
                        onClick={() => {
                            triggerScrollTop();
                        }}
                    >
                        <g>
                            <path
                                d="M120.027,962.802c26.6,0,53.5-8.801,75.7-27l288.1-234.7l290.899,237c22.301,18.1,49.101,27,75.7,27
		c34.8,0,69.4-15.101,93.101-44.2c41.899-51.4,34.1-127-17.2-168.8l-366.7-298.8c-44.1-36-107.5-36-151.6,0l-363.8,296.5
		c-51.4,41.8-59.1,117.399-17.3,168.8C50.727,947.702,85.227,962.802,120.027,962.802z"
                            />
                            <path
                                d="M120.027,541.902c26.6,0,53.5-8.8,75.7-27l288.1-234.7l290.899,237c22.301,18.101,49.101,27,75.7,27
		c34.8,0,69.4-15.1,93.101-44.2c41.899-51.399,34.1-127-17.2-168.8l-366.7-298.8c-44.1-36-107.5-36-151.6,0l-363.8,296.4
		c-51.4,41.9-59.1,117.5-17.3,168.9C50.727,526.802,85.227,541.902,120.027,541.902z"
                            />
                        </g>
                    </Svg>
                </div>
            )}
            <div
                ref={scrollContainerRef}
                className="h-screen max-h-screen w-full flex flex-col overflow-y-auto"
            >
                <div className="w-full flex items-center justify-center py-2 border-b shadow-pinterest">
                    <div className="flex flex-row items-center justify-center space-x-3">
                        {['/'].includes(pathname) ? (
                            <div className="rounded-full border-4 border-t-gray-500 border-l-red-500 border-b-green-500">
                                <CircularImage
                                    width={'w-[54px]'}
                                    className="shadow-2xl border-2 border-black rounded-full"
                                    src={cdnLink('./dksh.jpg')}
                                    hasLoader={true}
                                    loader={{
                                        width: 'w-[24px]',
                                        border: 'border-t-white border-l-red-500 border-b-green-500',
                                    }}
                                    background={
                                        'bg-black hero-pattern-glamorous-80'
                                    }
                                />
                            </div>
                        ) : (
                            <Svg
                                viewBox={'0 0 1024 1024'}
                                width={'w-[30px]'}
                                fill={'fill-red-500'}
                                className={
                                    'p-3 shadow-lg border-4 hero-pattern-glamorous-80 bg-white border-t-gray-500 border-l-red-500 border-b-green-500 rounded-full'
                                }
                            >
                                <path d="M946.5 505L534.6 93.4a31.93 31.93 0 0 0-45.2 0L77.5 505c-12 12-18.8 28.3-18.8 45.3 0 35.3 28.7 64 64 64h43.4V908c0 17.7 14.3 32 32 32H448V716h112v224h265.9c17.7 0 32-14.3 32-32V614.3h43.4c17 0 33.3-6.7 45.3-18.8 24.9-25 24.9-65.5-.1-90.5z"></path>
                            </Svg>
                        )}
                        <ActiveButton
                            loader={{
                                width: 'w-[28px]',
                                border: 'border-t-gray-500 border-l-red-500 border-b-green-500',
                            }}
                            onClick={() => {
                                navigateTo('/');
                            }}
                        >
                            <div className="text-2xl font-bold">DKSH</div>
                        </ActiveButton>
                    </div>
                </div>
                <div className="flex-grow">
                    <div className="w-full h-full min-h-[60vh]">{children}</div>
                </div>
                <div className="border-t-2">
                    <div className="flex h-full w-full items-center justify-center space-x-3 relative">
                        <div className="border-4 rounded-full border-t-gray-500 hero-pattern-hideout border-l-red-500 border-b-green-500">
                            <CircularImage
                                width={'w-[60px]'}
                                className="shadow-2xl border-2 border-black rounded-full"
                                src={cdnLink('./dksh.jpg')}
                                hasLoader={true}
                                loader={{
                                    width: 'w-[24px]',
                                    border: 'border-t-white border-l-red-500 border-b-green-500',
                                }}
                                background={
                                    'bg-black hero-pattern-glamorous-80'
                                }
                            />
                        </div>
                        <div className="py-[50px] text-2xl font-bold">
                            DKSH 2024
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
