import { addCommasToNumber, truncateToTwoDecimalPlaces } from '../utils';

export const TransferMoneyMessageTemplate = ({ statement }) => {
    return (
        <div>
            You have transfered{' '}
            {addCommasToNumber(truncateToTwoDecimalPlaces(statement?.amount))}{' '}
            DKSH to{' '}
            <span className="text-blue-600">
                {statement?.recipient?.fullname}{' '}
                {`@${statement?.recipient?.username}`}
            </span>
            .{' '}
            <span>
                Transaction cost{' '}
                {addCommasToNumber(truncateToTwoDecimalPlaces(statement?.fee))}{' '}
                DKSH.
            </span>{' '}
            Thank you for using our services. Your new balance is{' '}
            {addCommasToNumber(truncateToTwoDecimalPlaces(statement?.balance))}{' '}
            DKSH.
        </div>
    );
};
