export const scroll = (cb) => {
    return (e) => {
        const {
            scrollTop,
            scrollLeft,
            clientHeight,
            scrollHeight,
            clientWidth,
            scrollWidth,
        } = e.target;
        const yScrollPercentage =
            (scrollTop / (scrollHeight - clientHeight)) * 100;
        const xScrollPercentage =
            (scrollLeft / (scrollWidth - clientWidth)) * 100;

        cb({
            scrollTop,
            scrollLeft,
            clientHeight,
            clientWidth,
            scrollHeight,
            scrollWidth,
            yScrollPercentage,
            xScrollPercentage,
        });
    };
};
