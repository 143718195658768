import { useEffect, useState } from 'react';

export const useNumberAnimator = ({ endValue, duration }) => {
    const [startValue, setStartValue] = useState(0);
    const [currentValue, setCurrentValue] = useState(startValue);

    useEffect(() => {
        const startTime = Date.now();
        const previousEndValue = startValue;

        setStartValue(previousEndValue);

        const updateValue = () => {
            const currentTime = Date.now();
            const elapsed = currentTime - startTime;

            if (elapsed < duration) {
                const progress = elapsed / duration;
                const newValue =
                    previousEndValue +
                    Math.floor(progress * (endValue - previousEndValue));
                setCurrentValue(newValue);

                requestAnimationFrame(updateValue);
            } else {
                setCurrentValue(endValue);
            }
        };

        updateValue();
    }, [endValue, duration]);

    return { currentValue: currentValue };
};
