import { cdnLink } from '../utils';
import { CircularImage } from './CircularImage';

export const Logo = () => {
    return (
        <div className="flex h-full w-full items-center justify-center space-x-3">
            <div className="border-4 rounded-full border-t-gray-500 border-l-red-500 border-b-green-500">
                <CircularImage
                    width={'w-[50px]'}
                    className="shadow-2xl border-2 border-black rounded-full"
                    src={cdnLink('./dksh.jpg')}
                    hasLoader={true}
                    loader={{
                        width: 'w-[24px]',
                        border: 'border-t-white border-l-red-500 border-b-green-500',
                    }}
                    background={'bg-black hero-pattern-glamorous-70'}
                />
            </div>
            <div className="py-5 text-xl font-bold hidden md:flex">DKSH</div>
        </div>
    );
};
