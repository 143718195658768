export const Badge = ({ count, children }) => {
    return (
        <div className="relative">
            {(() => {
                if (count === 0 || typeof count === 'undefined') return;

                return (
                    count && (
                        <div className="iNOS-display-topright text-sm z-10">
                            <div
                                className={`w-[36px] bg-white border-t-gray-500 border-l-red-500 border-b-green-500 rounded-full jumia-box-shadow border-4 hero-pattern-glamorous overflow-hidden`}
                            >
                                <div className="w-full aspect-w-1 aspect-h-1 relative">
                                    <div className="iNOS-display-middle w-full h-full flex items-center justify-center text-red-500 font-bold text-[13px]">
                                        {count >= 100 ? <>99+</> : count}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                );
            })()}
            {children}
        </div>
    );
};
