import { Link as ReactRouterLink } from 'react-router-dom';
import { useLinkClick, useToast } from '../hooks';

export const Link = ({ children, onClick, to, ...params }) => {
    const { navigateTo } = useLinkClick();
    const { clear } = useToast();

    return (
        <ReactRouterLink
            {...params}
            onClick={(e) => {
                e.preventDefault();

                clear();

                if (typeof onClick === 'function') return onClick(e);

                if (typeof to === 'string') return navigateTo(to);
            }}
        >
            {children}
        </ReactRouterLink>
    );
};
