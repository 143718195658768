import { useEffect, useRef } from 'react';
import { useAceEditor } from '../../../../hooks';

export const PreviewEditor = ({ data }) => {
    const { ace } = useAceEditor();

    const editorRef = useRef();

    useEffect(() => {
        if (!ace) return;

        const codeEditor = ace.edit(editorRef.current);

        (() => {
            codeEditor.setTheme('ace/theme/tomorrow');
            codeEditor.session.setMode('ace/mode/javascript');
            codeEditor.setOptions({
                fontSize: '12pt',
                enableBasicAutocompletion: true,
                enableSnippets: true,
                enableLiveAutocompletion: false,
            });
            codeEditor.setValue(data && JSON.stringify({ ...data }, null, 4));
            codeEditor.clearSelection();
            codeEditor.setReadOnly(true);
        })();
    }, [ace, data]);

    return <div className="w-full h-full" ref={editorRef} />;
};
