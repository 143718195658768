export const UpcomingUpdate = ({ label }) => {
    return (
        <div className="space-y-1">
            <p>🎉 Exciting updates coming soon!</p>
            <p>
                We're working on the{' '}
                <span className="text-blue-500">{label}</span> section to bring
                you more features. Thanks for your patience!
            </p>
            <p>
                Explore existing features and stay tuned for the big reveal. -{' '}
                <span className="text-blue-500">DKSH Team</span>
            </p>
        </div>
    );
};
