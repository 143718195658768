export const Form = ({ onSubmit, ...props }) => {
    onSubmit = onSubmit || function () {};

    return (
        <form
            {...{ ...props }}
            onSubmit={(e) => {
                e.preventDefault();
                onSubmit(e);
            }}
        ></form>
    );
};
