import { useEffect, useRef, useState } from 'react';
import { useBackend, useDatabase } from '../../../../../hooks';
import { debounce, elementListener, scroll } from '../../../../../utils';
import { ActiveButton } from '../../../../ActiveButton';
import { Svg } from '../../../../Svg';
import { Loader } from '../../../../Loader';

export const CollectionHeader = ({ setPointer, pointer }) => {
    const { post } = useBackend();
    const {
        databaseCollections: { count, collections },
    } = useDatabase();
    const scrollEditorContainerRef = useRef();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(collections?.length !== count);
    }, [count, collections]);

    useEffect(() => {
        post(
            {
                exec: 'DatabaseCollections',
                client: {
                    items: 0,
                },
            },
            { auht: true, loader: false }
        );
    }, []);

    useEffect(() => {
        return elementListener(scrollEditorContainerRef)(
            'scroll',
            debounce(
                scroll(({ xScrollPercentage }) => {
                    xScrollPercentage > 40 &&
                        post(
                            {
                                exec: 'DatabaseCollections',
                                client: {
                                    items: collections.length,
                                },
                            },
                            {
                                auht: true,
                                loader: false,
                                lock: collections.length === count,
                            }
                        );
                }),
                300
            )
        );
    });

    return (
        collections && (
            <div
                ref={scrollEditorContainerRef}
                className="flex flex-row bg-green-500 hero-pattern-glamorous-60 w-full overflow-x-auto scrollbar-thin"
            >
                {[...collections].map((collection, i) => (
                    <ActiveButton
                        key={i}
                        loader={{
                            width: 'w-[20px]',
                            border: 'border-t-gray-500 border-l-white border-b-red-500',
                        }}
                        onClick={() => {
                            setPointer({
                                active: 'collection',
                                name: collection,
                            });
                        }}
                    >
                        <div
                            className={`px-5 ${
                                [pointer.name].includes(collection) &&
                                'shadow-md'
                            } py-3 space-x-4 flex flex-row items-center active:bg-red-900 active:text-red-600 active:hero-pattern-hideout`}
                        >
                            <div>
                                <Svg
                                    fill={'fill-green-700 active:fill-red-600'}
                                    width={'w-[24px]'}
                                    viewBox="0 -960 960 960"
                                >
                                    {[pointer.name].includes(collection) ? (
                                        <path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h240l80 80h320q33 0 56.5 23.5T880-640H447l-80-80H160v480l96-320h684L837-217q-8 26-29.5 41.5T760-160H160Zm84-80h516l72-240H316l-72 240Zm0 0 72-240-72 240Zm-84-400v-80 80Z" />
                                    ) : (
                                        <path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h240l80 80h320q33 0 56.5 23.5T880-640v400q0 33-23.5 56.5T800-160H160Zm0-80h640v-400H447l-80-80H160v480Zm0 0v-480 480Z" />
                                    )}
                                </Svg>
                            </div>
                            <div className="font-semibold max-w-[500px] text-green-700 flex flex-row">
                                <p className="truncate">{collection}</p>
                            </div>
                        </div>
                    </ActiveButton>
                ))}
                {loading && (
                    <div className="px-5 py-3 space-x-4 flex flex-row items-center">
                        <Loader
                            width={'w-[20px]'}
                            border={
                                'border-t-gray-500 border-l-white border-b-red-500'
                            }
                        />
                    </div>
                )}
            </div>
        )
    );
};
