import { useEffect } from 'react';
import { useBackend, useLinkClick, useSearchParams } from '../../hooks';
import { ActiveButton } from '../ActiveButton';
import { CircularImage } from '../CircularImage';
import { DKSH } from '../DKSH';
import { Svg } from '../Svg';
import { cdnLink } from '../../utils';

export const HomeUnauthenticated = () => {
    const { navigateTo } = useLinkClick();
    const { storage } = useBackend();

    const { referral_code } = useSearchParams();

    useEffect(() => {
        if (typeof referral_code === 'undefined') return;

        storage('referral_code', referral_code);
    }, [referral_code]);

    return (
        <div className="w-full h-full items-center justify-center flex flex-col py-10">
            <div className="flex flex-col space-y-10 md:space-y-7">
                <div className="w-full flex items-center justify-center">
                    <div className={`relative`}>
                        <div className="border-4 rounded-full bg-black border-t-gray-500 border-l-red-500 border-b-green-500">
                            <CircularImage
                                padding={'p-5'}
                                width={'w-[150px]'}
                                className={`rounded-full overflow-hidden shadow-lg border-4 border-black`}
                                src={cdnLink('./dksh.jpg')}
                                hasLoader={true}
                                loader={{
                                    width: 'w-[34px]',
                                    border: 'border-t-white border-l-red-500 border-b-green-500',
                                }}
                                background={
                                    'bg-black hero-pattern-glamorous-80'
                                }
                            />
                        </div>
                        <div className="iNOS-display-topleft -mt-5 -ml-5 p-5 z-[5000]">
                            <Svg
                                width={'w-[28px]'}
                                fill={'fill-red-500'}
                                className={
                                    'p-2 border-4 border-t-gray-500 border-l-red-500 border-b-green-500 hero-pattern-glamorous-70 bg-white rounded-full'
                                }
                                animate={'animate-pulse'}
                            >
                                <path d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2" />
                            </Svg>
                        </div>
                    </div>
                </div>
                <div className="space-y-5">
                    <div className="text-xl font-semibold italic font-serif text-center">
                        <DKSH />
                    </div>
                    <div className="text-2xl font-semibold font-serif text-center px-5">
                        Digital Kenya Shilling
                    </div>
                </div>
                <div className="w-full flex flex-row items-center justify-center">
                    <div className="flex flex-col sm:flex-row space-y-3 sm:space-y-0 space-x-5">
                        {[
                            {
                                path: (
                                    <>
                                        <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0m-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                                        <path d="M2 13c0 1 1 1 1 1h5.256A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1 1.544-3.393Q8.844 9.002 8 9c-5 0-6 3-6 4" />
                                    </>
                                ),
                                method: 'Sign up',
                                link: 'signup',
                            },
                            {
                                path: (
                                    <>
                                        <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.354-5.854 1.5 1.5a.5.5 0 0 1-.708.708L13 11.707V14.5a.5.5 0 0 1-1 0v-2.793l-.646.647a.5.5 0 0 1-.708-.708l1.5-1.5a.5.5 0 0 1 .708 0M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                                        <path d="M2 13c0 1 1 1 1 1h5.256A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1 1.544-3.393Q8.844 9.002 8 9c-5 0-6 3-6 4" />
                                    </>
                                ),
                                method: 'Sign in',
                                link: 'signin',
                            },
                        ].map((operation, i) => (
                            <div
                                key={i}
                                className="flex flex-row items-center justify-center space-x-5"
                            >
                                <Svg
                                    viewBox={operation?.viewBox}
                                    fill={'fill-red-500'}
                                    width={'w-[28px]'}
                                    className="p-3 border-4 border-t-gray-500 border-l-red-500 border-b-green-500 bg-white hero-pattern-glamorous-80  shadow-lg rounded-full"
                                >
                                    {operation?.path}
                                </Svg>
                                <ActiveButton
                                    loader={{
                                        width: 'w-[28px]',
                                        border: 'border-t-gray-500 border-l-red-500 border-b-green-500',
                                    }}
                                    onClick={() => {
                                        navigateTo(`/${operation?.link}`);
                                    }}
                                >
                                    <div className="font-bold text-xl border text-red-500 px-3 py-2 hero-pattern-glamorous bg-white rounded-full">
                                        {operation?.method}
                                    </div>
                                </ActiveButton>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
