import { createContext, useEffect, useRef, useState } from 'react';
import { useBackend, useSearchParams, useToast } from '../hooks';
import {
    ReceiveMoneyMessageTemplate,
    TransferMoneyMessageTemplate,
} from '../components';
import { useLocation } from 'react-router-dom';
import { LatestSort, _, base64ToBuffer } from '../utils';

export const SharedAccessContext = createContext();

export const SharedAccessProvider = ({ children }) => {
    const { listener, get, harvestBearerToken, subscribe, post } = useBackend();
    const [auth, setAuth] = useState({ ...harvestBearerToken() });
    const [payment, setPayment] = useState({});
    const [personalAccountBalance, setPersonalAccountBalance] = useState({
        savings: 0,
        wallet: 0,
    });
    const [personalAccountStatement, setPersonalAccountStatement] = useState(
        {}
    );
    const [personalAccountStatementCount, setPersonalAccountStatementCount] =
        useState(0);
    const [
        personalAccountStatementNotificationsCount,
        setPersonalAccountStatementNotificationsCount,
    ] = useState(0);

    const [
        personalAccountStatementObserver,
        setPersonalAccountStatementObserver,
    ] = useState(false);

    const [online, setOnline] = useState(false);

    const [scrollTop, setScrollTop] = useState(false);
    const scrollContainerRef = useRef();

    const { q } = useSearchParams();
    const { pathname } = useLocation();

    const [generalMetrics, setGeneralMetrics] = useState();
    const [reloadGeneralMetrics, setReloadGeneralMetrics] = useState(false);
    const [databaseStats, setDatabaseStats] = useState();
    const [databaseCollections, setDatabaseCollections] = useState();
    const [databaseCaches, setDatabaseCaches] = useState();
    const [databaseCollectionDocuments, setDatabaseCollectionDocuments] =
        useState();
    const [databaseCacheData, setDatabaseCacheData] = useState();

    const { set_htmlMessage, set_htmlError, setStatement, setMessage } =
        useToast();

    const triggerScrollTop = () => {
        scrollTop ? setScrollTop(false) : setScrollTop(true);
    };

    const triggerStatusOnline = () => {
        setOnline((previous) => !previous);
    };

    const triggerReloadGeneralMetrics = () => {
        setReloadGeneralMetrics((previous) => !previous);
    };

    const triggerPersonalAccountStatementObserver = () => {
        setPersonalAccountStatementObserver((previous) => !previous);
    };

    useEffect(() => {
        post({ exec: 'PersonalAccountBalance' }, { auth: true, loader: false });
        post(
            { exec: 'PersonalAccountStatementNotificationsCount' },
            { auth: true, loader: false }
        );
        post(
            { exec: 'PersonalAccountStatementCount' },
            { auth: true, loader: false }
        );
    }, [auth, online, personalAccountStatementObserver]);

    useEffect(() => {
        if (
            (['Statement'].includes(q) && ['/manager'].includes(pathname)) !==
            true
        )
            return;

        post(
            { exec: 'PersonalAccountStatementPatch' },
            { auth: true, loader: false }
        );
    }, [online]);

    useEffect(() => {
        return get(({ exec, data, error }) => {
            if (error) return set_htmlError(error?.message);

            if (['Signin', 'Signout'].includes(exec))
                return (() => {
                    setPersonalAccountStatement([]);
                    set_htmlMessage(data);
                })();

            if (['GenerateAccountRecoveryPassword', 'Signup'].includes(exec))
                return (() => {
                    const { arp, arp_name, message } = { ...data };

                    set_htmlMessage(message);

                    const url = window.URL.createObjectURL(
                        new Blob([base64ToBuffer(arp)], {
                            type: 'application/zip',
                        })
                    );

                    const a = document.createElement('a');

                    a.href = url;
                    a.download = arp_name;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(url);
                })();

            if (
                [
                    'UndoForgotPassword',
                    'ResetPassword',
                    'UndoSignup',
                    'UndoSignin',
                    'VerifyAccountRecoveryPassword',
                    'ForgotPassword',
                ].includes(exec)
            )
                return set_htmlMessage(data);

            if (['PersonalAccountSendMoney'].includes(exec))
                return (() => {
                    setPayment({ ...data });

                    setMessage(
                        <div>
                            Confirm payment of {data?.amount} DKSH to{' '}
                            <span className="text-blue-500">
                                {data?.fullname} {`@${data?.username}`}
                            </span>{' '}
                            . Transaction cost {data?.fee} DKSH. Please note
                            that this transaction is non-refundable.
                        </div>
                    );
                })();

            if (['PersonalAccountBalance'].includes(exec))
                return setPersonalAccountBalance({ ...data });

            if (['PersonalAccountStatement'].includes(exec))
                return (() => {
                    if (_.isUndefined(data?.offset)) return;

                    data.offset > 1
                        ? setPersonalAccountStatement((previousStatement) => {
                              return {
                                  ...previousStatement,
                                  ...Object.assign(
                                      {},
                                      ...[...data.items].map((statement) => ({
                                          [statement.uid]: statement,
                                      }))
                                  ),
                              };
                          })
                        : setPersonalAccountStatement({
                              ...Object.assign(
                                  {},
                                  ...[...data.items].map((statement) => ({
                                      [statement.uid]: statement,
                                  }))
                              ),
                          });
                })();

            if (['PersonalAccountStatementPatch'].includes(exec))
                return (() => {
                    setPersonalAccountStatement((previous) => {
                        return {
                            ...Object.assign(
                                {},
                                ...[...data].map((statement) => ({
                                    [statement.uid]: statement,
                                }))
                            ),
                            ...previous,
                        };
                    });
                })();

            if (['PersonalAccountConfirmPayment'].includes(exec))
                return (() => {
                    const { statement } = { ...data };

                    triggerPersonalAccountStatementObserver();

                    setStatement(
                        <TransferMoneyMessageTemplate {...{ statement }} />
                    );
                })();

            if (
                [
                    'PersonalAccountStatementNotificationsCount',
                    'PersonalAccountStatementNotificationsClear',
                ].includes(exec)
            )
                return setPersonalAccountStatementNotificationsCount(data);

            if (['PersonalAccountStatementCount'].includes(exec))
                return setPersonalAccountStatementCount(data);

            if (['GeneralMetrics'].includes(exec))
                return setGeneralMetrics((metrics) => {
                    return { ...metrics, ...data };
                });

            if (['DatabaseStats'].includes(exec)) return setDatabaseStats(data);

            if (['DatabaseCollections'].includes(exec))
                return (() => {
                    if (_.isUndefined(data?.offset)) return;

                    data.offset > 1
                        ? setDatabaseCollections((previous) => {
                              const { collections } = { ...previous };

                              return {
                                  count: data?.count,
                                  collections: [...collections, ...data.items],
                              };
                          })
                        : setDatabaseCollections({
                              count: data?.count,
                              collections: data.items,
                          });
                })();

            if (['DatabaseCaches'].includes(exec))
                return (() => {
                    if (_.isUndefined(data?.offset)) return;

                    data.offset > 1
                        ? setDatabaseCaches((previous) => {
                              const { collections } = { ...previous };

                              return {
                                  count: data?.count,
                                  caches: [...collections, ...data.items],
                              };
                          })
                        : setDatabaseCaches({
                              count: data?.count,
                              caches: data.items,
                          });
                })();

            if (['DatabaseCollectionDocuments'].includes(exec))
                return (() => {
                    if (_.isUndefined(data?.offset)) return;

                    data.offset > 1
                        ? setDatabaseCollectionDocuments((previous) => {
                              const { documents } = { ...previous };

                              return {
                                  stats: data.stats,
                                  documents: {
                                      ...documents,
                                      ...Object.assign(
                                          {},
                                          ...[...data.items].map(
                                              (document) => ({
                                                  [document.uid]: document,
                                              })
                                          )
                                      ),
                                  },
                              };
                          })
                        : setDatabaseCollectionDocuments({
                              stats: data.stats,
                              documents: Object.assign(
                                  {},
                                  ...[...data.items].map((document) => ({
                                      [document.uid]: document,
                                  }))
                              ),
                          });
                })();

            if (['DatabaseCacheData'].includes(exec))
                return (() => {
                    if (_.isUndefined(data?.offset)) return;

                    console.log(data);

                    data.offset > 1
                        ? setDatabaseCacheData((previous) => {
                              return {
                                  stats: data.stats,
                                  data: [...data.items, ...previous?.data],
                              };
                          })
                        : setDatabaseCacheData({
                              stats: data.stats,
                              data: data.items,
                          });
                })();
        });
    });

    useEffect(() => {
        return listener('bearer', (claims) => {
            const { isMember } = { ...claims };

            if (
                isMember &&
                typeof localStorage.getItem('referral_code') === 'string'
            )
                localStorage.removeItem('referral_code');

            setAuth({ ...claims });
        });
    });

    useEffect(() => {
        return listener('status', ({ status }) => {
            ['online'].includes(status) && triggerStatusOnline();
        });
    });

    useEffect(() => {
        return subscribe('GeneralMetrics', () => {
            triggerReloadGeneralMetrics();
        });
    });

    useEffect(() => {
        return subscribe('PersonalAccountReciveMoney', (data) => {
            const { statement } = { ...data };

            triggerPersonalAccountStatementObserver();

            statement?.isGenesis === true
                ? (() => {
                      setTimeout(() => {
                          setStatement(
                              <ReceiveMoneyMessageTemplate
                                  {...{
                                      statement,
                                      isSenderDKSHSavingsAccount:
                                          ['dksh'].includes(
                                              statement?.sender?.username
                                          ) &&
                                          ['Savings Account'].includes(
                                              statement?.sender?.fullname
                                          ),
                                      hasReferral:
                                          typeof statement?.referred !==
                                          'undefined',
                                  }}
                              />
                          );
                      }, [3000]);
                  })()
                : (() => {
                      if (
                          ['Statement'].includes(q) &&
                          ['/manager'].includes(pathname)
                      ) {
                          setPersonalAccountStatement((previousStatement) => {
                              return {
                                  [statement.uid]: statement,
                                  ...previousStatement,
                              };
                          });
                      }

                      setStatement(
                          <ReceiveMoneyMessageTemplate
                              {...{
                                  statement,
                                  isSenderDKSHSavingsAccount:
                                      ['dksh'].includes(
                                          statement?.sender?.username
                                      ) &&
                                      ['Savings Account'].includes(
                                          statement?.sender?.fullname
                                      ),
                                  hasReferral:
                                      typeof statement?.referred !==
                                      'undefined',
                              }}
                          />
                      );
                  })();
        });
    });

    return (
        <SharedAccessContext.Provider
            value={{
                auth: { ...auth },
                scroll: { triggerScrollTop, scrollTop, scrollContainerRef },
                payment: { ...payment, setPayment },
                wallets: {
                    personalAccountBalance: { ...personalAccountBalance },
                    personalAccountStatement: LatestSort(
                        personalAccountStatement
                    ),
                    personalAccountStatementNotificationsCount,
                    personalAccountStatementObserver,
                    personalAccountStatementCount,
                },
                status: { online },
                analytics: {
                    generalMetrics,
                    reloadGeneralMetrics,
                },
                database: {
                    databaseStats: { ...databaseStats },
                    databaseCollections: { ...databaseCollections },
                    databaseCaches: { ...databaseCaches },
                    databaseCollectionDocuments: {
                        ...databaseCollectionDocuments,
                        documents: LatestSort(
                            databaseCollectionDocuments?.documents
                        ),
                    },
                    databaseCacheData: {
                        ...databaseCacheData,
                        data: databaseCacheData?.data || [],
                    },
                },
            }}
        >
            {children}
        </SharedAccessContext.Provider>
    );
};
