export const instabool = function (global) {
    const identify = function (local, cb) {
        if (typeof global !== 'undefined') return cb(global);
        if (typeof local !== 'undefined') return cb(local);

        return cb();
    };

    const equate = (local, cb) => {
        if (typeof local !== 'undefined' && typeof global !== 'undefined')
            return cb(global, local);

        return cb();
    };

    return {
        isArray(local) {
            return identify(local, (value) => {
                return Array.isArray(value);
            });
        },
        isZip(local) {
            return identify(local, (buffer) => {
                return (
                    buffer[0] === 0x50 &&
                    buffer[1] === 0x4b &&
                    buffer[2] === 0x03 &&
                    buffer[3] === 0x04
                );
            });
        },
        isObject(local) {
            return identify(local, (value) => {
                if (typeof value === 'undefined' || value === null)
                    return false;

                return Object.getPrototypeOf(value) === Object.prototype;
            });
        },
        isUndefined(local) {
            return identify(local, (value) => {
                return typeof value === 'undefined';
            });
        },
        isUndefinedObject(local) {
            return identify(local, (value) => {
                if (this.isObject(value))
                    return Object.keys(value).length === 0;

                return true;
            });
        },
        isUndefinedArray(local) {
            return identify(local, (value) => {
                if (this.isArray(value)) return value.length === 0;

                return true;
            });
        },
        isNumber(local) {
            return identify(local, (value) => {
                return typeof value === 'number' && isNaN(value) !== true;
            });
        },
        isNumberInString(local) {
            return identify(local, (value) => {
                return /^[+-]?\d+(\.\d+)?$/.test(value);
            });
        },
        isString(local) {
            return identify(local, (value) => {
                return typeof value === 'string';
            });
        },
        isDate(local) {
            return identify(local, (value) => {
                return (
                    Object.prototype.toString.call(value) === '[object Date]'
                );
            });
        },
        isRegex(local) {
            return identify(local, (value) => {
                try {
                    new RegExp(value);

                    return true;
                } catch (error) {
                    return false;
                }
            });
        },
        isBoolean(local) {
            return identify(local, (value) => {
                return typeof value === 'boolean';
            });
        },
        isFunction(local) {
            return identify(local, (value) => {
                return typeof value === 'function';
            });
        },
        isObjectEqualTo(local) {
            return equate(local, (value1, value2) => {
                return [JSON.stringify(value1)].includes(
                    JSON.stringify(value2)
                );
            });
        },
        isShemaType(local) {
            return identify(local, (value) => {
                return [
                    this.isObject(value),
                    this.isString(value),
                    this.isBoolean(value),
                    this.isNumber(value),
                ].includes(true);
            });
        },
    };
};
