import { RefreshPage } from './RefreshPage';

export const Unavailable = () => {
    return (
        <div className="h-full w-full flex flex-col items-center justify-center py-7">
            <div className="felx flex-col px-5 space-y-5">
                <div className="text-center space-y-4">
                    <h1 className="text-5xl font-bold">503</h1>
                    <p className="text-xl mb-4 font-semibold">
                        Oops, something went wrong. Please check back later.
                    </p>
                </div>
                <RefreshPage />
            </div>
        </div>
    );
};
