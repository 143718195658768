import { useEffect, useState } from 'react';
import { cdnLink } from '../utils';

export const useAceEditor = () => {
    const [ace, setAce] = useState();
    const [error, setError] = useState();

    useEffect(() => {
        import(/* webpackIgnore: true */ `${cdnLink('./ace-editor/ace.js')}`)
            .then(async () => {
                await import(
                    /* webpackIgnore: true */ `${cdnLink(
                        './ace-editor/ext-language_tools.js'
                    )}`
                );

                window.ace.config.set('basePath', `${cdnLink('./ace-editor')}`);

                setAce(window?.ace);
            })
            .catch((error) => {
                setError(error);
            });
    }, []);

    return { ace, error };
};
