import { Svg } from '../../../Svg';
import { useAnalytics, useBackend } from '../../../../hooks';
import { useEffect } from 'react';
import { PreviewEditor } from './PreviewEditor';
import { ContentLoader } from '../../../ContentLoader';

export const GeneralMetrics = () => {
    const { generalMetrics, reloadGeneralMetrics } = useAnalytics();
    const { post } = useBackend();

    useEffect(() => {
        post({ exec: 'GeneralMetrics' }, { auth: true, loader: false });
    }, [reloadGeneralMetrics]);

    return (
        <div className="w-full max-w-screen-md m-auto">
            <div className="space-y-3">
                <div className="w-full relative space-y-5 md:space-y-0 md:border md:px-5 md:rounded-3xl md:hero-pattern-glamorous-80 md:shadow-lg md:bg-white overflow-hidden">
                    <div className="w-full flex items-center justify-center">
                        <div className="flex items-center justify-center space-x-5 md:py-3">
                            <div>
                                <Svg fill={'fill-red-500'} width={'w-[28px]'}>
                                    <path d="M4 11H2v3h2zm5-4H7v7h2zm5-5v12h-2V2zm-2-1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM6 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1zm-5 4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1z" />
                                </Svg>
                            </div>
                            <div className="font-bold text-red-500 text-lg">
                                General Metrics
                            </div>
                        </div>
                    </div>
                    <div className="w-full h-[70vh] relative border-4 border-t-gray-500 border-l-red-500 border-b-green-500 shadow-lg rounded-3xl bg-white overflow-hidden">
                        {generalMetrics ? (
                            <PreviewEditor data={generalMetrics} />
                        ) : (
                            <div className="w-full h-full flex items-center justify-center">
                                <ContentLoader width={'w-[100px]'} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
