import { useAuth, useBackend, useScroll, useSearchParams } from '../../hooks';

import React, { useEffect, useState } from 'react';
import { useWallets } from '../../hooks/useWallets';
import { StatementCard } from './components';
import { useLocation } from 'react-router-dom';
import { _, debounce, elementListener, scroll } from '../../utils';
import { ContentLoader } from '../ContentLoader';

export const Statement = () => {
    const { username } = useAuth();
    const {
        personalAccountStatement,
        personalAccountStatementNotificationsCount,
        personalAccountStatementCount,
    } = useWallets();
    const { post } = useBackend();

    const { scrollContainerRef } = useScroll();
    const { q } = useSearchParams();
    const { pathname } = useLocation();

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        personalAccountStatement.length &&
            setLoading(
                personalAccountStatement.length !==
                    personalAccountStatementCount
            );
    }, [personalAccountStatement, personalAccountStatementCount]);

    useEffect(() => {
        post(
            {
                exec: 'PersonalAccountStatement',
                client: { items: 0 },
            },
            {
                auth: true,
                loader: false,
            }
        );
    }, []);

    useEffect(() => {
        post(
            { exec: 'PersonalAccountStatementNotificationsClear' },
            {
                auth: true,
                loader: false,
                lock: personalAccountStatementNotificationsCount === 0,
            }
        );
    }, [personalAccountStatement]);

    useEffect(() => {
        return elementListener(scrollContainerRef)(
            'scroll',
            debounce(
                scroll(({ yScrollPercentage }) => {
                    ['Statement'].includes(q) &&
                        ['/manager'].includes(pathname) &&
                        yScrollPercentage > 50 &&
                        post(
                            {
                                exec: 'PersonalAccountStatement',
                                client: {
                                    items: personalAccountStatement.length,
                                },
                            },
                            {
                                auth: true,
                                loader: false,
                                lock:
                                    personalAccountStatement.length ===
                                    personalAccountStatementCount,
                            }
                        );
                }),
                300
            )
        );
    });

    return (
        <div className="w-full h-full items-center justify-center flex flex-col space-y-7 py-7 px-3">
            {[...personalAccountStatement].map((statement, i) => (
                <StatementCard
                    {...{
                        statement,
                        isSender: [username].includes(
                            statement?.sender?.username
                        ),
                        isRecipient: [username].includes(
                            statement?.recipient?.username
                        ),
                        isSenderDKSHSavingsAccount:
                            ['dksh'].includes(statement?.sender?.username) &&
                            ['Savings Account'].includes(
                                statement?.sender?.fullname
                            ),
                        hasReferral:
                            _.isUndefined(statement?.referred) !== true,
                        position: i + 1,
                    }}
                    key={i}
                />
            ))}
            {loading && (
                <ContentLoader
                    width={
                        personalAccountStatement.length
                            ? 'w-[80px]'
                            : 'w-[100px]'
                    }
                />
            )}
        </div>
    );
};
